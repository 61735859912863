import { Controller } from "@hotwired/stimulus"
import { get,post } from '@rails/request.js'

// Connects to data-controller="simple-calendar"
export default class extends Controller {

	static targets = [ "day", "shift", "event" ]

	initialize() {
		// to control if calendar is already loaded
		this.loaded = false;
		this.calendar = null;
		this.peopleId = 0;
		this.locationId = 0;
		this.shiftCopied = "";
		this.eventCopied = "";	
		this.currentYear = null;
		this.currentMonth = null;	

		
	}

  connect() {

		var $this = this

  	//console.log("start date:"+ $(".simple-calendar").attr("data-start"))
  	//console.log($(".simple-calendar").attr("data-user"))

  	if ($(".simple-calendar").attr("data-user")=="user") {
  		this.loadEmployeeCalendar();
  		return;
  	}
  	
  	this.loaded=true;

  	//$(".simple-calendar").css("visibility","hidden");

		var containerWidth = $(".simple-calendar .calendar-wrapper .row_").width();
		var resColumns=$(".simple-calendar .calendar-wrapper table.schedule th.resource-column").length;
		//const defaultWidth2=150;
		var defaultWidth2 = $(".simple-calendar").attr('data-column-width')

		if (resColumns*defaultWidth2+400 < containerWidth) {
			//add fake column
			if ($('.simple-calendar .calendar-wrapper table.schedule thead tr th.tot1').length > 0) {
				$('.simple-calendar .calendar-wrapper table.schedule thead tr th.tot1').before('<th class="fake-column"></th>');		
				//add cell in other rows
				$('.simple-calendar .calendar-wrapper table.schedule tr td.tot1').each(function() {
					$(this).before("<td></td>")
				})
			} else if ($('.simple-calendar .calendar-wrapper table.schedule thead tr th.tot2').length > 0) {
				$('.simple-calendar .calendar-wrapper table.schedule thead tr th.tot2').before('<th class="fake-column"></th>');		
				//add cell in other rows
				$('.simple-calendar .calendar-wrapper table.schedule tr td.tot2').each(function() {
					$(this).before("<td></td>")
				})				
			} else {
				$('.simple-calendar .calendar-wrapper table.schedule thead tr th.tot3').before('<th class="fake-column"></th>');		
				//add cell in other rows
				$('.simple-calendar .calendar-wrapper table.schedule tr td.tot3').each(function() {
					$(this).before("<td></td>")
				})				
			}

		}

		$(".multi-begin").closest("td").css("padding-bottom",0)
		$(".multi-end").closest("td").css("padding-top",0)
		$(".multi-in-between").closest("td").css("padding-top",0).css("padding-bottom",0)

  	if ($(".simple-calendar").attr("data-view")=="custom") {
  		$(".simple-calendar").css("visibility","visible");
  	} else {

  		$("#mistert-calendar").hide()
  		$("#mistert-calendar-nav").hide()

  		var peopleId=$(".simple-calendar").attr("data-people")
			this.peopleId = peopleId

	    var calendarEl = document.getElementById('fullcalendar');
	    $(calendarEl).addClass("p-3");

	    var initialDate;
	    initialDate = $(".simple-calendar").attr("data-start")
	    	     
	    /*
	    var calendar = new FullCalendar.Calendar(calendarEl, {
	      //events: '/main/calendar.json',
	      events: '/main/calendar.json?pid='+this.peopleId,
	      
	      headerToolbar: {
	        left  : 'prev,next today',
	        center: 'title',
	        right : 'dayGridMonth,dayGridWeek,dayGridDay'
	      },

	      themeSystem: 'bootstrap5',
	      locale: 'it',

	      editable  : true,
	      droppable : true, // this allows things to be dropped onto the calendar !!!
	      //aspectRatio: 1.35,
	      height: "auto",
	      //dayMaxEvents: true,
	      displayEventEnd: true,
	      eventTimeFormat: {
	        hour: 'numeric',
	        minute: '2-digit',
	        hour12: false
	      },
	      slotEventOverlap: false,
	      firstDay: 1,  

	      initialDate: initialDate, 

	      datesSet: function(info) {
	        if (calendar.view.type=="dayGridMonth") {
	          calendar.setOption('height', 'auto');
	        } else {
	          calendar.setOption('height', null);
	        }

					var startDate = info.start;

					if (startDate.getDate()==1) {
						var dd = String(startDate.getDate()).padStart(2, '0');
						var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
						var yyyy = startDate.getFullYear();
					} else {
						var dd = "01";
						var mm = String(startDate.getMonth() + 2).padStart(2, '0'); //January is 0!
						var yyyy = startDate.getFullYear();
					}
					startDate = yyyy + '-' + mm + '-' + dd;
	        //alert("change person: "+ startDate)
	        localStorage.setItem('calendarStart', startDate);

	      },           

	      eventDrop: function(info) {
	        alert(info.event.title + " was dropped on " + info.event.start.toISOString());
	      },
	    });
	    calendar.render();
	    */

			this.changePeople2(peopleId)


	    $("#fullcalendar-wrapper").height("calc(100% + 56px)");

	    $("#fullcalendar-nav").show()
			$("#fullcalendar").show()

			$(".simple-calendar").css("visibility","visible");


  	}
  	
		// worksites selection
		$('select.worksites').select2({
			theme: 'bootstrap-5'
		}).on('select2:select', function(e) {
			var data = e.params.data
			var placeId = data.id
			//window.location = "/shifts?ws=" + placeId + "&start_date=" + $(".simple-calendar").attr("data-start");
			window.location = "/shifts?ws=" + placeId;
		});

		// person selection
		$('select.people').select2({
			theme: 'bootstrap-5'
		}).on('select2:select', function(e) {
			$this.changePeople(e)
		});

		
		//$( ".multi-shift" ).draggable();
		$(".day").droppable({

			tolerance: "pointer",
			accept: ".shift, .event",

      drop: async function( event, ui ) {
        //recycleImage( ui.draggable );
        $(ui.draggable).fadeOut();
      

        //console.log("dropped!")

        //check if shift or event
        var type = $(ui.draggable).attr("data-type")

        if (type == "shift") {

	        //move shift
	        var shiftId = $(ui.draggable).attr("data-shift-id")
	        var startTime = $(ui.draggable).attr("data-start-time")
	        var endTime = $(ui.draggable).attr("data-end-time")
	        
	        //console.log(startTime)
	        //console.log(endTime)
	        //console.log($(this).find(".shifts-wrapper").attr("data-day"))
	        //console.log($(this).find(".shifts-wrapper").attr("data-resource-id"))

	        var targetDate = $(this).find(".shifts-wrapper").attr("data-day")
	        var targetStartTime = startTime.split(" ")[1]
	        var targetEndTime = endTime.split(" ")[1]
	        var targetResourceId = $(this).find(".shifts-wrapper").attr("data-resource-id")

	        // check contract hours
		    	const check = await get(`/shifts/check_employee_monthly_hours?eid=${targetResourceId}&date=${targetDate}&stime=${targetStartTime}&etime=${targetEndTime}`, {
		      	responseKind: "json"
		    	})

			    if (check.ok) {
			      const checkRes = await check.json
			      
			      //console.log(JSON.stringify(await response.json))
			      //console.log(checkRes.available)

			      if (!checkRes.available) {

			        var total_employee_shifts_hours = Math.floor(checkRes.total_employee_shifts / 60);
			        var total_employee_shifts_minutes = checkRes.total_employee_shifts % 60;
			        var current_contract_max_hours = Math.floor(checkRes.current_contract_max_minutes / 60);
			        var current_contract_max_minutes = checkRes.current_contract_max_minutes % 60;          
			    
			        Swal.fire({
			          title: i18n.t('hours_in_eccess_title'),
			          html: i18n.t('hours_in_eccess_msg1') + total_employee_shifts_hours + ':' + total_employee_shifts_minutes + i18n.t('hours_in_eccess_msg2') + current_contract_max_hours + ':' + current_contract_max_minutes,
			          icon: 'warning',
			          heightAuto: false,
			          showCancelButton: true,
			          confirmButtonColor: '#3085d6',
			          cancelButtonColor: '#d33',
			          confirmButtonText: i18n.t('hours_in_eccess_button'),
			          cancelButtonText: i18n.t('cancel')
			        }).then(async function(result) {
			          if (result.isConfirmed) {
			            // submit form
			            //console.log("copy shift")
							    const response = await post('/shifts/' + shiftId + '/move', { 
							      body: JSON.stringify({ date: targetDate, rid: targetResourceId }),
							      responseKind: "turbo-stream"
							    }) 
			          }
			        });

			      } else {
					    const response = await post('/shifts/' + shiftId + '/move', { 
					      body: JSON.stringify({ date: targetDate, rid: targetResourceId }),
					      responseKind: "turbo-stream"
					    }) 
			      }

			    }

			  } 

			  if (type == "event") {
			  	
			  	// event data
	        var eventId = $(ui.draggable).attr("data-event-id")
	        var startTime = $(ui.draggable).attr("data-start-time")
	        var endTime = $(ui.draggable).attr("data-end-time")			  

	        // target data
	        var targetDate = $(this).find(".shifts-wrapper").attr("data-day")
	        var targetStartTime = startTime.split(" ")[1]
	        var targetEndTime = endTime.split(" ")[1]
	        var targetResourceId = $(this).find(".shifts-wrapper").attr("data-resource-id")	    

	        // move event
			    const response = await post('/events/' + eventId + '/move', { 
			      body: JSON.stringify({ date: targetDate, rid: targetResourceId }),
			      responseKind: "turbo-stream"
			    }) 	        

			  }

      }
    });

		// context menu
		// vale sia per il personalizzato che per fullCalendar
		$.contextMenu({
		    // define which elements trigger this menu
		    selector: ".shift, .event",
		    // define the elements of the menu
		    items: {
		        copy: {name: "Copia", callback: function(itemKey, opt){
		        	
		        	//console.log("Clicked on " + itemKey + " on element " + opt.$trigger.attr("data-event-id")); 
		        	//console.log(opt.$trigger)
		        	//alert(opt.$trigger.attr("data-event-id"));
		        	
		        	if (opt.$trigger.hasClass("fc-event")) {
		        		// full calendar
		        		if (opt.$trigger.attr("data-type")=="Shift") {
		        			$this.shiftCopied = opt.$trigger.attr("data-event-id") 
		        		}
		        		if (opt.$trigger.attr("data-type")=="Event") {
		        			$this.eventCopied = opt.$trigger.attr("data-event-id");
		        		}		        		
		        	} else {
		        		//mistert calendar
		        		//console.log(opt.$trigger.attr("data-type"))
		        		if (opt.$trigger.attr("data-type")=="shift") {
		        			$this.shiftCopied = opt.$trigger.attr("id").split("_")[1]; 
		        		}
		        		if (opt.$trigger.attr("data-type")=="event") {
		        			$this.eventCopied = opt.$trigger.attr("data-event-id");
		        		}
		        	}
		        }}
		    }
		    
		});

		var el = document.querySelector('.schedule');
		var dragger = tableDragger(el, { mode: 'column'});
		dragger.on('drag',function(el, mode){
		  //console.log(el);
		  //console.log(mode);
		});
		dragger.on('drop', async function(from, to){
  		//console.log(from);
  		//console.log(to);
  		//ciclo sull'header
  		//console.log($(".resource-column"))

  		var arrPositions = []
  		$(".resource-column").each(function(i) { 
        //console.log($(".resource-column")[i].getAttribute("data-resource-id"))
        arrPositions.push(parseInt($(".resource-column")[i].getAttribute("data-resource-id")))
      }); 
	    //console.log(arrPositions);

	    var locationId = $(".simple-calendar").attr("data-worksite");
	    var startDate = $(".simple-calendar").attr("data-start");
	    var year = startDate.split("-")[0]
	    var month = startDate.split("-")[1]

      //save json object
	    const response = await post('/schedule_people_positions/sort', { 
	      body: JSON.stringify({ view: "resource_vertical", lid: locationId, year: year, month: month, positions: arrPositions }),
	      responseKind: "json"
	    })

		});

		// On window resize cells didn't resize correctly
		window.onresize = function() {
	  	if ($this.calendar) {
        if ($this.calendar.view.type=="dayGridMonth") {
          $this.calendar.setOption('height', 'auto');
        } else {
          $this.calendar.setOption('height', "calc(100% - 54px)");
        }
	  	}
		}

  }



	dayTargetConnected(element) {
    this.currentRow=$(element).closest("tr")
    this.dayHeight = 6
    this.newDayHeight = 6
    //deve farlo solo sull'ultima colonna
    var dayCell=$(element).closest("td")
    // se è l'ultima cella della riga (di tipo day)
    if (!dayCell.next().hasClass("day")) {
    	var shifts = this.currentRow.find('td.tot1').html()
    	var capacity = this.currentRow.find('td.tot2').html()
    	var diff = this.currentRow.find('td.tot3').attr("data-rem-capacity")
    	/*
    	if (diff=="OK") {
    		diff=0
    	} else {
    		diff = parseFloat(diff.replace(/\r?\n|\r/g, "").replace(/\t/g, ""))
    	}
    	*/
    	var day = this.currentRow.find('th').html()
    	if (diff == 0) {
    		this.currentRow.find('.capacity').html('<i class="fa-solid fa-circle-check" style="color:#6baa75;"></i> ' + shifts + ' / ' + capacity) 
    	} else if (diff > 0) {
    		this.currentRow.find('.capacity').html('<i class="fa-solid fa-circle-xmark" style="color:#f24333;"></i> ' + shifts + ' / ' + capacity)
    	} else {
    		this.currentRow.find('.capacity').html('<i class="fa-solid fa-triangle-exclamation" style="color:#ffa434;"></i> ' + shifts + ' / ' + capacity)
    		//this.currentRow.find('.capacity').html(shifts + ' / ' + capacity)    	
    	}
    }
  }

  shiftTargetConnected(element) {

  	//alert("shiftTargetConnected")

		//new shift position and row height
		if ( $(element).hasClass("multi-spacer") ) {
			this.newDayHeight += $(element).height()
		}

		if ( $(element).hasClass("multi-wrapper") ) {
			var saveElementHeight = $(element).height();
			$(element).css("position","absolute").css("top", this.newDayHeight+"px").css("bottom", "-35px").css("left","6px").css("right","6px")
			//console.log("Element height: "+$(element).height())
			this.newDayHeight += saveElementHeight
		}

		if ( $(element).hasClass("single") ) {
			$(element).css("position","absolute").css("top", this.newDayHeight+"px").css("left","6px").css("right","6px")  		
			this.newDayHeight += $(element).height() 
		}

		// set row height if total day height is greater than current row height
		var currentRowHeight=$(element).closest("tr").height()
		//console.log("Current row height: "+currentRowHeight)

		if ( $(element).hasClass("multi-wrapper") ) { 
			if ( this.newDayHeight > currentRowHeight ) {
				$(element).closest("tr").height(this.newDayHeight)
			}
		} else {
			if ( (this.newDayHeight+4) > currentRowHeight ) {
				$(element).closest("tr").height(this.newDayHeight+4)
			}
		}

		$(element).find(".shift").draggable({
			distance: 3,
			stack: ".shift"
		});
		$(element).find(".multi-shift").draggable({
			distance: 3,
			stack: ".multi-shift"
		});
  }

  getMaxCellHeightInARow($row) {

  	var maxCellHeight=0

		$row.find('td.day').each(function() {
			var cellHeight=0;
      // loop shifts and events in a day
      $(this).find(".shift-wrapper, .event-wrapper").each(function() {
      	cellHeight+=$(this).height();
      });
      cellHeight+=10
      if (cellHeight>maxCellHeight) {
      	maxCellHeight=cellHeight
      }
   	});
   	return maxCellHeight
  }

	eventTargetConnected(element) {

		//alert("eventTargetConnected")

		// set event position
		$(element).css("position","absolute").css("top", this.newDayHeight+"px").css("left","6px").css("right","6px")  		
		this.newDayHeight += $(element).height()

		// called only when update days
		if (this.loaded) {
			this.currentRow.height(this.getMaxCellHeightInARow(this.currentRow))
		}
		
		// set row height if total day height is greater than current row height
		var currentRowHeight=$(element).closest("tr").height()
		if ( (this.newDayHeight+4) > currentRowHeight ) {
			$(element).closest("tr").height(this.newDayHeight+4)
		}

		$(element).find(".event").draggable({
			distance: 3,
			stack: ".event"
		});		
  }

  pasteShift(event) {

  	//console.log("pasteShift")

  	event.preventDefault()	
  	//alert("paste shift:" + this.shiftCopied)
  	var locationId = $('.worksites').select2('data')[0].id
  	//var sourceShiftId = this.shiftCopied.split("_")[1]
  	var sourceShiftId = this.shiftCopied

  	//console.log($(event.target).closest(".day").find(".shifts-wrapper"))
  	if ($(event.target).closest("#fullcalendar-wrapper").length>0) {
  		// FC
  		var targetDay = $(event.target).closest(".fc-day").attr("data-date")
  		var targetResourceId =  $("#fullcalendar-wrapper select.people").val()
  		locationId = 0
  	} else {
  		// custom vertical calendar
  		var targetDay = $(event.target).closest(".day").find(".shifts-wrapper").attr("data-day")
  		var targetResourceId = $(event.target).closest(".day").find(".shifts-wrapper").attr("data-resource-id")
  	}
  	//console.log("targetDay: " + targetDay)
  	//console.log("targetResourceId: " + targetResourceId)

  	//call copy shift
  	this.copy(locationId, sourceShiftId, targetDay, targetResourceId)
  }

  async pasteEvent(event) {
  	event.preventDefault()	
  	//alert("paste event:" + this.eventCopied)
  	var locationId = $('.worksites').select2('data')[0].id
  	//var sourceShiftId = this.shiftCopied.split("_")[1]
  	var sourceEventId = this.eventCopied

  	//alert($(event.target).closest(".day").find(".shifts-wrapper"))


  	if ($(event.target).closest("#fullcalendar-wrapper").length>0) {
  		// FC
  		var targetDay = $(event.target).closest(".fc-day").attr("data-date")
  		var targetResourceId =  $("#fullcalendar-wrapper select.people").val()
  	} else {
  		// custom vertical calendar
  		var targetDay = $(event.target).closest(".day").find(".shifts-wrapper").attr("data-day")
  		var targetResourceId = $(event.target).closest(".day").find(".shifts-wrapper").attr("data-resource-id")
  	}
  	//alert("targetDay: " + targetDay)
  	//alert("targetResourceId: " + targetResourceId)

  	//call copy shift
  	//this.copyEvent(locationId, sourceShiftId, targetDay, targetResourceId)

    const response = await post('/events/copy_single', { 
      body: JSON.stringify({ lid: locationId, eid: sourceEventId, pid: targetResourceId, date: targetDay }),
      responseKind: "turbo-stream"
    })

    if ($("#fullcalendar-nav").css("display")!='none') {
    	//refresh fc
    	this.calendar.refetchEvents()
    }    
  }

  async copy(locationId, sourceShiftId, targetDay, targetResourceId) {
  	
  	//alert("copy")

  	// save calendar instance
  	var thisCalendar = this.calendar

  	// save controller instance
  	var $this = this
  	
  	//console.log("locationId:" + locationId)
  	//console.log("sourceShiftId:" + sourceShiftId)
  	//console.log("targetDay:" + targetDay)
  	//console.log("targetResourceId:" + targetResourceId)

    //ajax call to check emp monthly hours
    const check = await get(`/shifts/check_before_copy_single?pid=${targetResourceId}&sid=${sourceShiftId}&date=${targetDay}`, {
      responseKind: "json"
    })

    if (check.ok) {
      const checkRes = await check.json
      //console.log(checkRes)  

      if (!checkRes.available) {

        var total_people_shifts_hours = Math.floor(checkRes.total_people_shifts / 60);
        var total_people_shifts_minutes = checkRes.total_people_shifts % 60;
        var current_contract_max_hours = Math.floor(checkRes.current_contract_max_minutes / 60);
        var current_contract_max_minutes = checkRes.current_contract_max_minutes % 60;          
    
        Swal.fire({
          title: i18n.t('hours_in_eccess_title'),
          html: i18n.t('hours_in_eccess_msg1') + total_people_shifts_hours + ':' + total_people_shifts_minutes + i18n.t('hours_in_eccess_msg2') + current_contract_max_hours + ':' + current_contract_max_minutes,
          icon: 'warning',
          heightAuto: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: i18n.t('hours_in_eccess_button'),
          cancelButtonText: i18n.t('cancel')
        }).then(async function(result) {
          if (result.isConfirmed) {
            // submit form
            //alert("copy shift")
				    const response = await post('/shifts/copy_single', { 
				      body: JSON.stringify({ lid: locationId, sid: sourceShiftId, pid: targetResourceId, date: targetDay }),
				      responseKind: "turbo-stream"
				    })
				    if ($("#fullcalendar-nav").css("display")!='none') {
				    	// refresh fc
				    	thisCalendar.refetchEvents()
				    	// update monthly hours info
				    	$this.updateMonthlyHoursInfo()
				    }

          }
        });

      } else {

		    const response = await post('/shifts/copy_single', { 
		      body: JSON.stringify({ lid: locationId, sid: sourceShiftId, pid: targetResourceId, date: targetDay }),
		      responseKind: "turbo-stream"
		    })
		    if ($("#fullcalendar-nav").css("display")!='none') {
		    	// refresh fc
		    	thisCalendar.refetchEvents()
		    	// update monthly hours info
		    	$this.updateMonthlyHoursInfo()
		    }

      }

    }  
  }

  changeView(event) {
  	event.preventDefault()	

  	//alert("change view")
  	
  	if (event.params.type=="people-month") {

  		

  		if (this.peopleId) {
  			
  			window.Turbo.navigator.history.push({ href: "shifts?view=people&p="+this.peopleId });

  			$("#mistert-calendar").hide()
  			$("#mistert-calendar-nav").hide()  	

		    $("#fullcalendar-wrapper").height("calc(100% + 56px)");

		    $("#fullcalendar-nav").show()
		    $("#fullcalendar").addClass("p-3")
				$("#fullcalendar").show()  					
  			return;
  		}

  		window.Turbo.navigator.history.push({ href: "shifts?view=people" });

  		$("#mistert-calendar").hide()
  		$("#mistert-calendar-nav").hide()

	    var calendarEl = document.getElementById('fullcalendar');
	    $(calendarEl).addClass("p-3");

	    var initialDate;
	    initialDate = $(".simple-calendar").attr("data-start")
	    	     
	    var calendar = new FullCalendar.Calendar(calendarEl, {
	      //events: '/main/calendar.json',
	      //events: '/main/calendar.json?pid='+this.peopleId,
	      headerToolbar: {
	        left  : 'prev,next today',
	        center: 'title',
	        right : 'dayGridMonth,dayGridWeek,dayGridDay'
	      },

	      themeSystem: 'bootstrap5',
	      locale: 'it',

	      editable  : true,
	      droppable : true, // this allows things to be dropped onto the calendar !!!
	      //aspectRatio: 1.35,
	      height: "auto",
	      //dayMaxEvents: true,
	      displayEventEnd: true,
	      eventTimeFormat: {
	        hour: 'numeric',
	        minute: '2-digit',
	        hour12: false
	      },
	      slotEventOverlap: false,
	      firstDay: 1,  

	      initialDate: initialDate, 

	      datesSet: function(info) {
	        if (calendar.view.type=="dayGridMonth") {
	          calendar.setOption('height', 'auto');
	        } else {
	          calendar.setOption('height', null);
	        }

					var startDate = info.start;

					if (startDate.getDate()==1) {
						var dd = String(startDate.getDate()).padStart(2, '0');
						var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
						var yyyy = startDate.getFullYear();
					} else {
						var dd = "01";
						var mm = String(startDate.getMonth() + 2).padStart(2, '0'); //January is 0!
						var yyyy = startDate.getFullYear();
					}
					startDate = yyyy + '-' + mm + '-' + dd;
	        //alert("change person: "+ startDate)
	        localStorage.setItem('calendarStart', startDate);

	      },           

	      eventDrop: function(info) {
	        alert(info.event.title + " was dropped on " + info.event.start.toISOString());
	        
	        /*
	        console.log(info.event.start.toISOString());
	        console.log(info.event.end.toISOString());
	        console.log(info.event.id);
	        var startTime = moment(info.event.start).format("YYYY-MM-DD HH:mm");
	        var endTime = moment(info.event.end).format("YYYY-MM-DD HH:mm");

	        //if (!confirm("Are you sure about this change?")) {
	          //info.revert();
	        //} else {
	          $.ajax({
	            type: "POST",
	            url: "/events/" + info.event.id + "/move_event",
	            data: "start=" + startTime + "&end=" + endTime,
	            dataType: 'script',
	            success: function(resp){
	              console.log(resp)
	            }
	          });
	        //}
	        */
	      },
	    });
	    calendar.render();

	    $("#fullcalendar-wrapper").height("calc(100% + 56px)");

	    $("#fullcalendar-nav").show()
			$("#fullcalendar").show()

  	}
  	
  	if (event.params.type=="mistert") {

  		window.Turbo.navigator.history.push({ href: "shifts?view=custom" });

  		//check localstorage
	    var initialDate;
	    if(localStorage.getItem('calendarStart')!=null){
	    	initialDate = localStorage.getItem('calendarStart');
	    } else {
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();
				initialDate = yyyy + '-' + mm + '-' + dd;
	    }

  		$("#fullcalendar-nav .people").prop('selectedIndex',0)
  		$("#fullcalendar").removeClass("p-3")
  		
  		//NON SO PERCHE' AVEVO SVUOTATO IL DIV - FORSE PER PROBLEMI CON LA VISTA PERSONALIZZATA?
  		//NEL CASO RIUTILIZZARE E COMMENTARE HIDE
  		//$("#fullcalendar").html("")
  		$("#fullcalendar").hide()

  		$("#fullcalendar-nav").hide()

  		$("#fullcalendar-wrapper").height("0");
  		
      /*
      var placeId = $(".simple-calendar").attr("data-worksite");
      window.location = "/shifts?ws=" + placeId + "&start_date="+initialDate;
      */
  		$("#mistert-calendar-nav").show()
  		$("#mistert-calendar").show()

  	}
  }

  async changePeople(event) {
  	console.log("change people")
  	var peopleId=$(event.currentTarget.selectedOptions[0]).val()
		this.changePeople2(peopleId)		
  }

  changePeople2(peopleId) {

  	console.log("change people 2")

  	window.Turbo.navigator.history.push({ href: "shifts?view=people&p="+peopleId });

  	//console.log($(event.target).html())
  	//console.log(JSON.stringify(event.params))
  	//console.log($(event.currentTarget.selectedOptions[0]).val())

  	//var peopleId=$(event.currentTarget.selectedOptions[0]).val()
		this.peopleId = peopleId

		// put people id in data-people-id attr
		$("#fullcalendar-wrapper").attr("data-people-id", peopleId)

		// change link a.delete-shifts
	  $("a.delete-shifts").attr('href', function(i,a){
	    return a.replace( /(pid=)[0-9]+/ig, '$1'+peopleId );
	  });

		// change link a.delete-events
	  $("a.delete-events").attr('href', function(i,a){
	    return a.replace( /(pid=)[0-9]+/ig, '$1'+peopleId );
	  });	  

		
		// change link a.print-employee-calendar
	  $("a.print-employee-calendar").attr('href', function(i,a){
	    return a.replace( /(pid=)[0-9]+/ig, '$1'+peopleId );
	  });

		// change link a.publish-employee-calendar
	  $("a.publish-employee-calendar").attr('href', function(i,a){
	    return a.replace( /(pid=)[0-9]+/ig, '$1'+peopleId );
	  });	  
		
    var calendarEl = document.getElementById('fullcalendar');

    var initialDate;
    if(localStorage.getItem('calendarStart')!=null){
    	initialDate = localStorage.getItem('calendarStart');
    } else {
			var today = new Date();
			var dd = String(today.getDate()).padStart(2, '0');
			var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = today.getFullYear();
			initialDate = yyyy + '-' + mm + '-' + dd;
    }
    //alert(initialDate)

    var $this = this

    var calendar = new FullCalendar.Calendar(calendarEl, {
      events: '/main/calendar.json?pid='+peopleId,
      headerToolbar: {
        left  : 'prev,next today',
        center: 'title',
        right : 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      },

      themeSystem: 'bootstrap5',
      locale: 'it',

      editable  : true,
      droppable : true, // this allows things to be dropped onto the calendar !!!
      //aspectRatio: 1.35,
      height: "auto",
      //dayMaxEvents: true,
      displayEventEnd: true,
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: false
      },
      slotEventOverlap: false,
      firstDay: 1,  
      initialDate: initialDate, 
      slotDuration: '00:10:00',
      /*allDaySlot: false,*/

      showNonCurrentDates: false,
      fixedWeekCount: false,

      eventDidMount: function(info) {
      	info.el.setAttribute("data-type", info.event.extendedProps.type); 
        info.el.setAttribute("data-event-id", info.event.id);  
        info.el.setAttribute("data-turbo-frame", "remote_modal");  
        // https://github.com/fullcalendar/fullcalendar/issues/450
        if(info.event.extendedProps.notes != '' && typeof info.event.extendedProps.notes  !== "undefined" && info.event.extendedProps.notes != null) {
        	$(info.el).find(".fc-event-title-container").append('<div class="notes">'+info.event.extendedProps.notes+'</div>'); 
        }        
      },    

      datesSet: async function(info) {
      	
        if (calendar.view.type=="dayGridMonth") {
          calendar.setOption('height', 'auto');
        } else {
          calendar.setOption('height', "calc(100% - 54px)");
        }

				var startDate = info.start;
				//alert(startDate)
				if (startDate.getDate()==1) {
					var dd = String(startDate.getDate()).padStart(2, '0');
					var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
					var yyyy = startDate.getFullYear();
				} else {
					var dd = "01";
					var mm = String(startDate.getMonth() + 2).padStart(2, '0'); //January is 0!
					var yyyy = startDate.getFullYear();
				}
				startDate = yyyy + '-' + mm + '-' + dd;

				$this.currentYear=yyyy
				$this.currentMonth=mm			

        $("#fullcalendar").attr("data-year", yyyy)
        $("#fullcalendar").attr("data-month", mm)					
        $(".simple-calendar").attr("data-start", startDate)

				var endDate = info.end; //ore 00:00 del giorno successivo
				//alert(endDate)
					var dd_end = String(endDate.getDate()).padStart(2, '0');
					var mm_end = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
					var yyyy_end = endDate.getFullYear();
					endDate = yyyy_end + '-' + mm_end + '-' + dd_end;	
					//alert(endDate)			

        //console.log(startDate)
        localStorage.setItem('calendarStart', startDate);
        localStorage.setItem('calendarCurrentYear', yyyy);
        localStorage.setItem('calendarCurrentMonth', mm);

        

        
				// change m param in link a.print-employee-calendar
			  $("a.print-employee-calendar").attr('href', function(i,a){
			    return a.replace( /(m=)[0-9\-]+/ig, '$1'+ yyyy + '-' + mm );
			  });
				// change sd param in link a.print-employee-calendar
			  $("a.print-employee-calendar").attr('href', function(i,a){
			    return a.replace( /(sd=)[0-9\-]+/ig, '$1'+ startDate );
			  });

				// change sd param in link a.publish-employee-calendar
			  $("a.publish-employee-calendar").attr('href', function(i,a){
			    return a.replace( /(sd=)[0-9\-]+/ig, '$1'+ startDate );
			  });	
				// change ed param in link a.publish-employee-calendar
			  $("a.publish-employee-calendar").attr('href', function(i,a){
			    return a.replace( /(ed=)[0-9\-]+/ig, '$1'+ endDate );
			  });			  		  

			  $this.updateMonthlyHoursInfo()

      }, 

			eventClassNames: function(arg) {
				var classes = ""
			  if (arg.event.extendedProps.type == "Event") {
			  	if (arg.event.extendedProps.published == false) {
			  		return [ 'event unpublished' ]
			  	} else {
			  		return [ 'event' ]
			  	}
			  } else {
			  	if (arg.event.extendedProps.published == false) {
			    	return [ 'shift unpublished' ]
			    } else {
			    	return [ 'shift' ]
			    }
			  }
			},      

      eventDrop: async function(info) {
        //console.log(info.event.title + " was dropped on " + info.event.start.toString());
        //console.log(info.event.start.toString());
        //console.log(info.event.end.toString());
        //console.log(info.event.id);

        var startTime = info.event.start
        var endTime = info.event.end
        alert(startTime)
        alert(endTime)
        alert(info.event.extendedProps.type)
        // convert in "yyyy-mm-dd hh:mm" local format
        startTime = startTime.getFullYear()+'-' + (startTime.getMonth()+1) + '-'+startTime.getDate() + ' ' + startTime.getHours() + ':' + startTime.getMinutes()
        endTime = endTime.getFullYear()+'-' + (endTime.getMonth()+1) + '-'+endTime.getDate() + ' ' + endTime.getHours() + ':' + endTime.getMinutes()

		    if (info.event.extendedProps.type == "Shift") {
			    const response = await post('/shifts/' + info.event.id + '/move', { 
			      body: JSON.stringify({ start: startTime, end: endTime }),
			      responseKind: "json"
			    })		    	
		    }

		    if (info.event.extendedProps.type == "Event") {
			    const response = await post('/events/' + info.event.id + '/move', { 
			      body: JSON.stringify({ start: startTime, end: endTime }),
			      responseKind: "json"
			    })		    	
		    }		    

		    const checkRes = await response.json
		    if (checkRes.unavailabilites.length>0) {
		    	info.revert();
		    }
      },   

		  eventResize: async function(info) {
        var startTime = info.event.start
        var endTime = info.event.end
		    const response = await post('/shifts/' + info.event.id + '/move', { 
		      body: JSON.stringify({ start: startTime, end: endTime }),
		      responseKind: "turbo-stream"
		    })   
		  },

	    dayCellContent: function (info) {
	    	//console.log(info.date.getDate())
	    	
				var dd = String(info.date.getDate()).padStart(2, '0');
				var mm = String(info.date.getMonth()+1).padStart(2, '0'); //January is 0!
				var yyyy = info.date.getFullYear();	  
				var date = yyyy + '-' + mm + '-' + dd;
				//console.log(date)
	 
	    	//var html="";
	    	//return { html: '<i>some html</i>'+info.dayNumberText }
	    	var button = '';
	    	button += '<a data-bs-toggle="dropdown" class="float-end" style="position:absolute;top:5px;right:5px;z-index:10;" href="#">';
        button += '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">';
        button += '<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>';
        button += '</svg>';
        button += '</a>';

				button += '<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(128px, 31px);"><a data-bs-toggle="dropdown" class="float-end" style="position:absolute;top:5px;right:5px;z-index:10;" href="#">';
        button += '</a><li><a data-bs-toggle="dropdown" class="float-end" style="position:absolute;top:5px;right:5px;z-index:10;" href="#">';
        button += '</a><a data-turbo-frame="remote_modal" class="dropdown-item" href="/shifts/new?date='+date+'&amp;eid='+peopleId+'">Nuovo turno/evento</a>';
        button += '</li>';
        button += '<li>';
        button += '<a data-action="click->simple-calendar#pasteShift" class="dropdown-item" href="#">Incolla turno</a>';
        button += '</li>';        
        //button += '<li>';
        //button += '<a data-turbo-frame="remote_modal" class="dropdown-item" href="/events/new?date='+date+'&amp;eid='+peopleId+'">Nuovo evento</a>';
        //button += '</li>';

        button += '<li>';
        button += '<a data-action="click->simple-calendar#pasteEvent" class="dropdown-item" href="#">Incolla evento</a>';
        button += '</li>';

        button += '</ul>';

				var number = document.createElement("a");
      	number.classList.add("fc-daygrid-day-number");
      	number.innerHTML = info.dayNumberText;
      //if (info.view.type === "dayGridMonth") {
        return {
          html: number.outerHTML + button
        };
      //}

	    }

    });





		this.calendar = calendar;
    this.calendar.render();

  }  



  loadEmployeeCalendar() {
  	//console.log("load my calendar")

  	var peopleId=2;

	    var calendarEl = document.getElementById('fullcalendar');

	    var calendar = new FullCalendar.Calendar(calendarEl, {
	      events: '/main/calendar.json?pid='+peopleId,
	      headerToolbar: {
	        left  : 'prev,next today',
	        center: 'title',
	        right : 'dayGridMonth,dayGridWeek,dayGridDay'
	      },

	      themeSystem: 'bootstrap5',

	      editable  : true,
	      droppable : true, // this allows things to be dropped onto the calendar !!!
	      //aspectRatio: 1.35,
	      height: "auto",
	      //dayMaxEvents: true,
	      displayEventEnd: true,
	      eventTimeFormat: {
	        hour: 'numeric',
	        minute: '2-digit',
	        hour12: false
	      },
	      slotEventOverlap: false,
	      firstDay: 1,   

	      datesSet: function(info) {
	        if (calendar.view.type=="dayGridMonth") {
	          calendar.setOption('height', 'auto');
	        } else {
	          calendar.setOption('height', null);
	        }
	      },           
	        
	    });

	    calendar.render();
	    //$("#fullcalendar-nav").show()
			$("#fullcalendar").show()

			$(".simple-calendar").css("visibility","visible");
  }

  // update all shifts and events
  async refreshCalendar(event) { 

  	console.log("refresh calendar")
  	
  	// check if peopleId=0 (error when custom view)
  	var peopleId = this.peopleId
  	if (peopleId==0) {
  		return
  	}
  	//console.log(event.detail.formSubmission.formElement.id)
  	//console.log(event.detail.formSubmission.formElement.dataset.controller)
  	//console.log(event.detail.formSubmission.formElement[0].name)
  	//console.log(event.detail.formSubmission.formElement[0].value)

  	var currentYear = $("#fullcalendar").attr("data-year")
  	var currentMonth = $("#fullcalendar").attr("data-month")
  	
  	if (this.calendar) {
  		this.calendar.refetchEvents()
  	}

    // update monthly hours info
  	const peopleMonthlyHours = await get(`/employees/get_people_monthly_hours?pid=${this.peopleId}&year=${currentYear}&month=${currentMonth}`, {
    	responseKind: "json"
  	})
		if (peopleMonthlyHours.ok) {
			const res = await peopleMonthlyHours.json
			//console.log(res)

			//convert in hh:mm format
			const current_contract_hours = Math.floor(res.current_contract_max_minutes / 60)
			const current_contract_minutes = res.current_contract_max_minutes % 60

			const shifts_hours = Math.floor(res.total_employee_shifts / 60)
			const shifts_minutes = res.total_employee_shifts % 60

			$("#contract-hours").html(current_contract_hours + ":" + current_contract_minutes)
			$("#shifts-hours").html(shifts_hours + ":" + shifts_minutes)
			$("#monthly-hours").show()			
		}

  } 

  async updateMonthlyHoursInfo() {

    // update monthly hours info
  	const peopleMonthlyHours = await get(`/employees/get_people_monthly_hours?pid=${this.peopleId}&year=${this.currentYear}&month=${this.currentMonth}`, {
    	responseKind: "json"
  	})
		if (peopleMonthlyHours.ok) {
			const res = await peopleMonthlyHours.json
			//console.log(res)

			//convert in hh:mm format
			const current_contract_hours = Math.floor(res.current_contract_max_minutes / 60)
			const current_contract_minutes = res.current_contract_max_minutes % 60

			const shifts_hours = Math.floor(res.total_employee_shifts / 60)
			const shifts_minutes = res.total_employee_shifts % 60

			$("#contract-hours").html(current_contract_hours + ":" + current_contract_minutes)
			$("#shifts-hours").html(shifts_hours + ":" + shifts_minutes)
			$("#monthly-hours").show()			
		}

  }

  async refreshFC() {

  	console.log("refreshFC")

  	// check if peopleId=0 (error when custom view)
  	var peopleId = this.peopleId
  	if (peopleId==0) {
  		return
  	}  	

  	var currentYear = $("#fullcalendar").attr("data-year")
  	var currentMonth = $("#fullcalendar").attr("data-month")
  	
  	if (this.calendar) {
  		this.calendar.refetchEvents()
  	}

    // update monthly hours info
  	const peopleMonthlyHours = await get(`/employees/get_people_monthly_hours?pid=${this.peopleId}&year=${currentYear}&month=${currentMonth}`, {
    	responseKind: "json"
  	})
		if (peopleMonthlyHours.ok) {
			const res = await peopleMonthlyHours.json
			//console.log(res)

			//convert in hh:mm format
			const current_contract_hours = Math.floor(res.current_contract_max_minutes / 60)
			const current_contract_minutes = res.current_contract_max_minutes % 60

			const shifts_hours = Math.floor(res.total_employee_shifts / 60)
			const shifts_minutes = res.total_employee_shifts % 60

			$("#contract-hours").html(current_contract_hours + ":" + current_contract_minutes)
			$("#shifts-hours").html(shifts_hours + ":" + shifts_minutes)
			$("#monthly-hours").show()			
		}

  }

}




