// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "@rails/actioncable"

import "trix"
import "@rails/actiontext"

//import '@popperjs/core/lib/popper'

//import * as bootstrap from "bootstrap"
import "./src/bootstrap"



/*
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.bootstrap5Plugin = bootstrap5Plugin;
*/

import "./src/jquery"
import "./src/jquery-ui"


import myPlugin from "./src/myPlugin"
window.myPlugin = myPlugin;
import PluginNameHere from "./src/PluginNameHere" 
window.PluginNameHere = PluginNameHere;
import TestPlugin from "./src/testPlugin"
window.TestPlugin = TestPlugin;



import Gantt3 from "./src/gantt3";
window.Gantt3 = Gantt3;
//window.eat('🍕');
//var myGantt3 = new Gantt3();
//myGantt3.doSomething()

import { addDays, format, intervalToDuration, differenceInCalendarDays, differenceInMinutes, startOfWeek, startOfMonth, addMonths } from 'date-fns';
window.format=format;
window.addDays=addDays;
window.intervalToDuration=intervalToDuration;
window.differenceInCalendarDays=differenceInCalendarDays;
window.differenceInMinutes=differenceInMinutes;
window.startOfWeek=startOfWeek;
window.startOfMonth=startOfMonth;
window.addMonths=addMonths;

import gantt from "./src/gantt" 
window.gantt = gantt;

import Gantt2 from "./src/gantt2" 
window.Gantt2 = Gantt2;

import Gantt4 from "./src/gantt4" 
window.Gantt4 = Gantt4;

//import Gantt5 from "./src/gantt5" 
//window.Gantt5 = Gantt5;

import DataTable from "datatables.net-bs5"
window.DataTable = DataTable();
/* import "@fortawesome/fontawesome-free/js/all"; */

import "./components/clock.jsx";
//import "./components/calendar.jsx";

import 'jsrender/jsrender';
//import 'syncfusion-javascript/Scripts/ej/web/ej.web.all.min';

//import "./src/xlsx.full.min"
//import * as XLSX from "./src/xlsx.core.min"
import * as XLSX from "xlsx"
window.XLSX = XLSX;

import "./src/tableExport"
import jsPDF from "./src/jspdf.umd.min"
window.jsPDF = jsPDF;


//import autoTable from './src/jspdf.plugin.autotable';
//window.autoTable = autoTable;

import 'bootstrap-table';
import 'bootstrap-table/dist/bootstrap-table-locale-all';
import 'bootstrap-table/dist/extensions/export/bootstrap-table-export';
import 'bootstrap-table/dist/extensions/mobile/bootstrap-table-mobile';
import 'bootstrap-table/dist/extensions/custom-view/bootstrap-table-custom-view';
import 'bootstrap-table/dist/extensions/print/bootstrap-table-print';
import 'bootstrap-table/dist/extensions/addrbar/bootstrap-table-addrbar';
/*
import 'bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header';
*/




import { I18n } from "i18n-js";
import translations from "./locales.json";
const i18n = new I18n(translations);
i18n.defaultLocale = "it";
i18n.locale = "it";
window.i18n = i18n;

$(function() {
	console.log("hellddd13")
	$( "#dialog" ).dialog();
	console.log(i18n.t('cancel'));
});

import select2 from 'select2';
select2();

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min';

import 'jquery-contextmenu';

import LocalTime from "local-time"
LocalTime.config.i18n["it"] = {
  date: {
    dayNames: [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato"
    ],
    abbrDayNames: [
      "Dom",
      "Lun",
      "Mar",
      "Mer",
      "Gio",
      "Ven",
      "Sab"
    ],    
    monthNames: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ],
    abbrMonthNames: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic"
    ],    
    yesterday: "ieri",
    today: "oggi",
    tomorrow: "domani",
    on: "{date}",
    formats: {
      default: "%e %b %Y",
      thisYear: "%e %b"    
    }
  },

  time: {
    am: "am",
    pm: "pm",
    singular: "a {time}",
    singularAn: "an {time}",
    elapsed: "{time} fa",
    second: "secondo",
    seconds: "secondi",
    minute: "minuto",
    minutes: "minuti",
    hour: "ora",
    hours: "ore",
    formats: {
      default: "%H:%M"
    }
  },

  datetime: {
    at: "{date} alle {time}",
    formats: {
      default: "%e %B %Y alle %l:%M%P %Z"
    }
  }




}
LocalTime.config.locale = "it"
LocalTime.start()

//import { menuController } from '@ionic/core/dist/ionic/index.esm';
//window.menuController = menuController;

import tableDragger from 'table-dragger'
window.tableDragger = tableDragger;

//import dragtable from 'dragtable'

$.contextMenu({
    // define which elements trigger this menu
    selector: ".with-cool-menu",
    // define the elements of the menu
    items: {
        foo: {name: "Foo", callback: function(key, opt){ alert("Foo!"); }},
        bar: {name: "Bar", callback: function(key, opt){ alert("Bar!") }}
    }
    // there's more, have a look at the demos and docs...
});

//import "./src/evo-calendar"

import Dropzone from "dropzone";

import "@nathanvda/cocoon";

import mybutton from "./src/openui5"
//import "./src/openui5"
import message from "./src/message"
console.log(message())

import "chartkick/chart.js"

//non ricordo perchè esistono le istruzioni seguenti
//commento per il momento
/*
let scrollTop = 0

addEventListener("turbo:before-visit", () => {
	console.log("turbo:before-visit")
	scrollTop = document.scrollingElement.scrollTop
	console.log("scrollTop: "+scrollTop)
});

addEventListener("turbo:render", () => {
	console.log('turbo:render')
	if (scrollTop) {
		Turbo.navigator.currentVisit.scrolled = true
    document.scrollingElement.scrollTo(0, scrollTop)
  }
  scrollTop = 0
});
*/

/*
addEventListener("turbo:before-cache", () => {
	console.log('turbo:before-cache')
});


addEventListener("turbo:submit-end", (event) => {
	console.log('turbo:submit-end')
	console.log(event.detail)
});



*/

addEventListener("turbo:before-stream-render", (event) => {
  console.log('turbo:before-stream-render')
  console.log(event.detail)
  //var dt  = $("#datatables-container table").DataTable();
  //dt.rows().invalidate().draw()//ToDo tighter invalidation strategy
});


addEventListener("turbo:load", (event) => {
	//console.log('turbo:load')
	//console.log($(event.detail))
	//$("#evo-calendar").evoCalendar();
	

//in alcuni casi progressbar non viene nascosta dopo il caricamento
setTimeout(function() {
    Turbo.navigator.delegate.adapter.progressBar.hide()
}, 300);


});


import { get,post } from '@rails/request.js'
$(document).on('click','#navbarDropdownNotifications', function(e){
	e.preventDefault()
	const response = get(`/notifications/reset_count`, {
		responseKind: "turbo-stream"
	})
});





//import './functions'
//window.test2 = MyFunctions.test2()
//window.employeesFormatter = MyFunctions.employeesFormatter()import "trix"
import "@rails/actiontext"
