import { Controller } from "@hotwired/stimulus"
//import test2 from "../functions"
//import * as MyFunctions from '../functions'

// Connects to data-controller="employees"
export default class extends Controller {
  connect() {
  	
    /*
    if (!$.fn.DataTable.isDataTable( '#employees table' )) {
	 		$('#employees table').DataTable( {
			  "columnDefs": [ {
			    "targets"  : 'nosort',
			    "orderable": false,
			  }]
			});  		
  	}
    */
 	
  	console.log('employees connect');

    $('#kanban-table').on('custom-view-post-body.bs.table', function () {
      //alert("custom-view-post-body.bs.table");
    })

   

  }
  teardown() {
  	/* $('#employees table').DataTable().destroy();*/
  	console.log('employees teardown');
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  }  
}
