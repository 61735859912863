import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="timesheets--timesheets"
export default class extends Controller {
  connect() {
    console.log("timesheets connect")
  	$('#timesheets table').bootstrapTable({});
  	/*$('#timesheets table').bootstrapTable('refresh', {silent: true});*/
  }
  teardown() {
  	console.log("tear")
  	$("#timesheets table").bootstrapTable('destroy');
  }

}
