import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payroll--payslip-line"
export default class extends Controller {
  connect() {
  	console.log("payroll--payslip-line - connect")

    $(document).on('blur','#payroll_payslip_line_quantity',function(e){
      var qty = $(this).val()
      var amt = $("#payroll_payslip_line_amount").val()
      var total = qty * amt
      $("#payroll_payslip_line_total").val(total)
    });

    $(document).on('blur','#payroll_payslip_line_amount',function(e){
      var amt = $(this).val()
      var qty = $("#payroll_payslip_line_quantity").val()
      var total = qty * amt
      $("#payroll_payslip_line_total").val(total)
    });

  }

  //da rivedere completamente (forse non serve più in quanto il calcolo viene fatto sui campi qtà e amt)
  calculate(element) {

 

  	console.log("payroll--payslip-line - calculate")
  	console.log($(element.target).val())
  	
  	var lineCode = $(element.target).closest("form").find("#payroll_payslip_line_code").val()
  	console.log(lineCode)
  	
  	if (lineCode == "MFE50") {
  		var qty = $(element.target).val()
  		var hourlyWageBase = $("#hourly-wage-base-amount").val()
  		var total = (hourlyWageBase * 0.5) * qty
  		$("#payroll_payslip_line_total").val(total)
  	}

  	if (lineCode == "M6G25") {
  		var qty = $(element.target).val()
  		var hourlyWageBase = $("#hourly-wage-base-amount").val()
  		var total = (hourlyWageBase * 25 / 100) * qty
  		$("#payroll_payslip_line_total").val(total)
  	}

  	if (lineCode == "MNO20") {
  		var qty = $(element.target).val()
  		var hourlyWageBase = $("#hourly-wage-base-amount").val()
  		var total = (hourlyWageBase * 20 / 100) * qty
  		$("#payroll_payslip_line_total").val(total)
  	}

  	if (lineCode == "FSADO") {
  		var days = $(element.target).val() // quantity=days
  		var partTimePercent = $("#part-time-percent").val()
  		var oreFisso = 173 * partTimePercent / 100
  		var monthDays = $("#payroll_payslip_month_days").val()
  		var qty = oreFisso / monthDays * days // hours
  		var hourlyWage = $("#hourly-wage-amount").val()
  		var total = hourlyWage * qty
  		$("#payroll_payslip_line_total").val(total)
  	}  

  	if (lineCode == "FSETT") {
  		var days = $(element.target).val() // quantity=days
  		var weekDays = $("#week-days").val()
  		var weekHours = $("#week-hours").val()
  		var qty = weekHours / weekDays * days // hours
  		var hourlyWage = $("#hourly-wage-amount").val()
  		var total = hourlyWage * qty
  		$("#payroll_payslip_line_total").val(total)
  	}  

  	if (lineCode == "13IMA") {
  		var qty = $(element.target).val() // quantity=days
  		var hourlyWage = $("#hourly-wage-amount").val()
  		var total = hourlyWage * qty
  		$("#payroll_payslip_line_total").val(total)
  	} 

  	if (lineCode == "14IMA") {
  		var qty = $(element.target).val() // quantity=days
  		var monthlySalary = $("#monthly-salary-amount").val()
  		var total = (monthlySalary - 10.33) / 173 * qty
  		$("#payroll_payslip_line_total").val(total)
  	}   	

  }
}
