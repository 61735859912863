import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="warehouse-operation-form"
export default class extends Controller {

	static targets = ["select_operation_type", "source_location", "target_location"]

  connect() {

    console.log("ok")
  	console.log("warehouse-operation-form connect")

    $('#rows').on('cocoon:before-insert', async function(e, insertedItem, originalEvent) {
      console.log("added line")
      console.log(e)
      console.log(originalEvent)

      var sourceLocationId = $("#warehouse_operation_source_location_id").find("option:selected").val()
      console.log(sourceLocationId)
      //alert("partenza: " + sourceLocationId)

      //chck if customview
      var customView=false
      if ($(originalEvent.target).closest(".fixed-table-custom-view").length>0) {
        //alert("it's a custom view!")
        customView=true
      }
      
      if (customView) {
        var imageUrl = $(originalEvent.target).closest(".product").find("img").attr("src")
        //alert(imageUrl)
        var productId = $(originalEvent.target).closest(".product").attr("data-record-id")
        //alert(productId)
        var productSku = $(originalEvent.target).closest(".product").attr("data-product-sku")
        //alert(productSku)
        var productName = $(originalEvent.target).closest(".product").attr("data-product-name")
        //alert(productName)
        var productType = $(originalEvent.target).closest(".product").attr("data-product-type")
        var productFamily = $(originalEvent.target).closest(".product").attr("data-product-family")
        var qty = $(originalEvent.target).closest(".product").find("input.qty").val()  
        //alert(qty)      
      } else {
        var imageUrl = $(originalEvent.target).closest("tr").find("img").attr("src")
        var productId = $(originalEvent.target).closest("tr").attr("data-product-id")
        var productSku = $(originalEvent.target).closest("tr").attr("data-product-sku")
        var productName = $(originalEvent.target).closest("tr").attr("data-product-name")
        var qty = $(originalEvent.target).closest("tr").find("input.qty").val()        
      }

/*
      var productId = $(originalEvent.target).closest("tr").attr("data-product-id")
      var productName = $(originalEvent.target).closest("tr").attr("data-product-name")
      //alert("prodotto: " + productId)
      var qty = $(originalEvent.target).closest("tr").find("input.qty").val() 
      //alert("quantità: " + qty)
*/

/*
    var confirmation = confirm("Are you sure?");
    if( confirmation === false ){
      e.preventDefault();
    }
*/

     
      // check avail in source location
      var productAvailable = true

      if (sourceLocationId != "") {
        const response = await get(`/warehouse_operations/get_available_qty?pid=${productId}&lid=${sourceLocationId}`, {
          responseKind: "json"
        })
        if (response.ok) {
          var res = await response.json
          var availableQty = res.available_qty
          console.log(JSON.stringify(res)) 
          if (qty > availableQty) {
            //e.preventDefault()
            insertedItem.remove()          
            //alert("Il prodotto " + productName + " non è disponibile nella ubicazione di partenza.")
            var html = ''
            html += '<div data-delay="5000" class="toast" role="alert" aria-live="assertive" aria-atomic="true">'
            html += '<div class="toast-header">'
            html += '<strong class="me-auto">Prodotto non disponibile</strong>'
            html += '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>'
            html += '</div>'
            html += '<div class="toast-body">Prodotto <strong>' + productName + '</strong> non disponibile nell\'ubicazione di partenza</div>'
            html += '</div>'
            $("#toasts").append(html)
            var toastElList = [].slice.call(document.querySelectorAll('.toast'))
            var toastList = toastElList.map(function (toastEl) {
              return new bootstrap.Toast(toastEl, {autohide: false})
            })
            toastList.forEach((t) => {
              t.show();
            });
            productAvailable = false
          }
        }
      }    


/*
      //chck if customview
      var customView=false
      if ($(originalEvent.target).closest(".fixed-table-custom-view").length>0) {
        //alert("it's a custom view!")
        customView=true
      }
      
      if (customView) {
        var imageUrl = $(originalEvent.target).closest(".product").find("img").attr("src")
        //alert(imageUrl)
        var productId = $(originalEvent.target).closest(".product").attr("data-record-id")
        //alert(productId)
        var productSku = $(originalEvent.target).closest(".product").attr("data-product-sku")
        //alert(productSku)
        var productName = $(originalEvent.target).closest(".product").attr("data-product-name")
        //alert(productName)
        var productType = $(originalEvent.target).closest(".product").attr("data-product-type")
        var productFamily = $(originalEvent.target).closest(".product").attr("data-product-family")
        var qty = $(originalEvent.target).closest(".product").find("input.qty").val()  
        //alert(qty)      
      } else {
        var imageUrl = $(originalEvent.target).closest("tr").find("img").attr("src")
        var productId = $(originalEvent.target).closest("tr").attr("data-product-id")
        var productSku = $(originalEvent.target).closest("tr").attr("data-product-sku")
        //var productName = $(originalEvent.target).closest("tr").attr("data-product-name")
        //var qty = $(originalEvent.target).closest("tr").find("input.qty").val()        
      }
*/

      // check if row exists
      var $rows = $("#rows .row.nested-fields")
      $rows.each(function(index, row) { 
        if ($(row).attr("data-product-id")==productId) {
          //alert("la riga esiste: "+productId)
          //sostituisce con il nuovo valore
          $(row).find(".qty").val(qty)
          //annulla l'aggiunta della riga
          e.preventDefault()
          return
        }
      });

      if (customView) {
        insertedItem.attr("data-product-id", productId)
        insertedItem.find(".product-id").val(productId)
       
        insertedItem.find(".product-name").html(productName + '[' + productSku + ']')
        insertedItem.find(".product-type").html(productType)
        insertedItem.find(".product-family").html(productFamily)

        //insertedItem.find(".qty-ordered").html(qty)
        insertedItem.find(".qty").val(qty)
        insertedItem.find(".image").attr("src", imageUrl)

        // clean qty in modal row
        $(originalEvent.target).closest(".product").find("input.qty").val("")      //custom view

        if (productAvailable) {
          $(originalEvent.target).closest(".product").find(".qty-added").html(qty) 
          var recordId = $(originalEvent.target).closest(".product").attr("data-record-id")
          //alert(recordId)
          var rowIndex = $('#products-table-custom tbody tr[id=product_'+recordId+']').attr("data-index")

             
          //alert(rowIndex)
          $('#products-table-custom').bootstrapTable('updateRow', {
            index: rowIndex,
            row: {
              qty_added: qty
            }
          });
        }


      } else {
        insertedItem.attr("data-product-id", productId)
        insertedItem.find(".product-id").val(productId)
        insertedItem.find(".product-sku").val(productSku)
        insertedItem.find(".product-name").val(productName)
        //insertedItem.find(".qty-ordered").val(qty)
        insertedItem.find(".qty").val(qty)
        insertedItem.find(".image").attr("src", imageUrl) 

        // clean qty in modal row
        $(originalEvent.target).closest("tr").find("input.qty").val("")  
        
        if (productAvailable) {
          $(originalEvent.target).closest("tr").find(".qty-added").html(qty) 
          var rowIndex = $(originalEvent.target).closest("tr").attr("data-index")          
          $('#products-table').bootstrapTable('updateRow', {
            index: rowIndex,
            row: {
              qty_added: qty
            }
          });
        }

      }
      console.log($(originalEvent.target).closest("tr").attr("data-product-name"))
    });

    $('#rows').on('cocoon:before-remove', function(e, removedItem) {
      removedItem.removeAttr("data-product-id")
    });



    

    //if called by request add lines to form automatically
    /*
    if ($("#warehouse_operation_request_id").val() != "") {
      var $productsTableRows = $("#exampleModal table.products tr")
      $productsTableRows.each(function(index, tr) { 
        //alert($(tr).find("td.qty-wrapper").html())
        if ($(tr).find(".qty").val() != "" && $(tr).find(".qty").val() !== undefined) {
          //alert("click")
          $(tr).find(".add_fields").click()
        }
      });
    }
    */
    

  }

  addProductsForm(event) {
    event.preventDefault();
    //alert("add products")



    var $table = $("#exampleModal table.products")
    // check if customview
    var customView = $table.attr("data-show-custom-view")
    //alert(customView)

    if (customView) {

      var $productsTableRows = $("#exampleModal .bootstrap-table .fixed-table-custom-view .row .list-group-item")
      $productsTableRows.each(function(index, tr) { 
        console.log(index)
        console.log(tr)
        console.log($(tr).find("input.qty").val())
        if ($(tr).find("input.qty").val() != "") {
          $(tr).find(".add_fields").click()
          $(tr).find("input.qty").val("")
        }        
      });  


    } else {

      $(".toast").remove();

      //ciclo sulle righe prodotti che hanno il campo quantità valorizzato
      var $productsTableRows = $("#exampleModal table.products tr")
      $productsTableRows.each(function(index, tr) { 
        console.log(index);
        console.log(tr);
        console.log($(tr).find(".qty").val())
        if ($(tr).find(".qty").val() != "") {
          $(tr).find(".add_fields").click()
          $(tr).find(".qty").val("")
        }
      });      

    }




 

    const truck_modal = document.querySelector('#exampleModal');
    const modal = window.bootstrap.Modal.getInstance(truck_modal);
    modal.hide();


    //clear quantity





  }

  toggle() {
  	console.log("selected")
  	console.log(this.select_operation_typeTarget.value)
  	// carico
  	if (this.select_operation_typeTarget.value==1) {
  		this.source_locationTarget.hidden = true
  		this.target_locationTarget.hidden = false
  	}
  	
  	// scarico
  	if (this.select_operation_typeTarget.value==2) {
  		this.source_locationTarget.hidden = false
  		this.target_locationTarget.hidden = true
  	}

  	// spostamento
  	if (this.select_operation_typeTarget.value==3) {
  		this.source_locationTarget.hidden = false
  		this.target_locationTarget.hidden = false
  	}

  	// rettifica
  	if (this.select_operation_typeTarget.value==4) {
  		this.source_locationTarget.hidden = true
  		this.target_locationTarget.hidden = false
  	}

    // acquisto (va in magazzino centrale)
    if (this.select_operation_typeTarget.value==11) {
      this.source_locationTarget.hidden = true
      this.target_locationTarget.hidden = true
    }

    // trasferimento
    if (this.select_operation_typeTarget.value==12) {
      this.source_locationTarget.hidden = true
      this.target_locationTarget.hidden = true
    }  

    // assegnazione
    if (this.select_operation_typeTarget.value==13) {
      this.source_locationTarget.hidden = true
      this.target_locationTarget.hidden = false
    }  

    // consumo
    if (this.select_operation_typeTarget.value==14) {
      this.source_locationTarget.hidden = false
      this.target_locationTarget.hidden = true
    }          


  }

  changeOperationType(event) {
    const prefix = $(event.target).find("option:selected").attr("data-prefix")
    const counter = parseInt($(event.target).find("option:selected").attr("data-counter")) + 1
    const ref=prefix + "-" + counter 
    //fill ref field
    $("#warehouse_operation_operation_number").val(ref)  

    const sourceLocationId = $(event.target).find("option:selected").attr("data-source-location-id")
    const targetLocationId = $(event.target).find("option:selected").attr("data-target-location-id")

    //fill source location field
    $("#warehouse_operation_target_location_id").val(targetLocationId)

    //fill target location field
    $("#warehouse_operation_source_location_id").val(sourceLocationId)

  }

/*
  async selectOperationType(event) {
    //alert("selectOperationType")

    console.log($(event.target).val())
    const operationTypeId=$(event.target).val()

    //get operation type entry from db
    
    //ajax call to check emp monthly hours
    const response = await get(`/warehouse_operation_types/${operationTypeId}`, {
      responseKind: "json"
    })
    if (response.ok) {
      const res = await response.json
      console.log(JSON.stringify(await response.json))
      
      
      const prefix = res.prefix
      const counter = res.counter+1
      const ref=prefix + "-" + counter
      //alert(ref)

      const sourceLocationId=res.source_location_id
      const targetLocationId=res.target_location_id

      //fill ref field
      $("#warehouse_operation_operation_number").val(ref)
    
      //fill source location field
      $("#warehouse_operation_target_location_id").val(targetLocationId)


      //fill target location field
      $("#warehouse_operation_source_location_id").val(sourceLocationId)



    }
    

  }
  */
}
