import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="publish-form"
export default class extends Controller {
  connect() {
  	console.log("publish-form connect")
  }

  refreshCalendar() {
    console.log("publish-form -> refreshCalendar")
    window.dispatchEvent(new CustomEvent("refreshFC"));
  }

}
