import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bootstrap-table"
export default class extends Controller {
  connect(event) {
  	console.log("bootstrap table connect")
    //alert($(window).width())
    
    
    $('#table-container table').bootstrapTable({
    });
    
/*
    function DoBeforeAutotable(table, headers, rows, AutotableSettings) {
      var doc = AutotableSettings.tableExport.doc
      var title = table.attr("data-title")
      doc.setFontSize(20);
      doc.setTextColor(40);                      
      doc.text(title, AutotableSettings.margin.left, 60);
    }
  	
  	$('#table-container table').bootstrapTable({
      exportOptions: {
          jspdf: {
              orientation: "p",
              autotable: {
                  startY: 70,
                  tableExport: {onBeforeAutotable: DoBeforeAutotable}
              }
          }
      }
    });
  */  

    var $el = $(this.element)

    $(document).on('click','table.row-click-enabled tr',function(e){  
      //alert("click")
      //alert($(this).attr("data-post-id"));
      var tableName = $el.attr("data-table")
      var fieldName = $el.attr("data-field")
      //alert(tableName)
      //alert(fieldName)
      //alert($(this).data(fieldName))
      Turbo.visit("/"+tableName+"/" + $(this).data(fieldName))      
    });

    $(document).on('click-row.bs.table','table.row-click-enabled',function(e, row, $element, field){  
      //if (field != "actions") { 
        
        alert('click su riga')
        //alert($el.attr("data-table"))
        var tableName = $el.attr("data-table")
        var fieldName = $el.attr("data-field")
        //alert(fieldName)
        Turbo.visit("/"+tableName+"/" + $element.data(fieldName))
      //}
    });  

  	// Warehouse Operation - products selection modal
  	//if called by request add lines to form automatically
  	if ($("#exampleModal table.products").length > 0) {
	    if ($("#warehouse_operation_request_id").val() != "") {
	      var $productsTableRows = $("#exampleModal table.products tr")
	      $productsTableRows.each(function(index, tr) { 
	        if ($(tr).find(".qty").val() != "" && $(tr).find(".qty").val() !== undefined) {
	          //alert("click")
	          $(tr).find(".add_fields").click()
	        }
	      });
	    }
	  }

  }

  teardown() {
  	console.log("tear")
  	$("#table-container table").bootstrapTable('destroy');
  }

  refresh() {
  }

}
