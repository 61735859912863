import { Controller } from "@hotwired/stimulus"
//import { menuController } from '@ionic/core/dist/ionic/index.esm';
//window.menuController = menuController;


// Connects to data-controller="mobile--menu"
export default class extends Controller {
  connect() {
  	console.log("mobile menu connect")
  }

  openFirst() {
  	console.log("first menu clicked")

    //await menuController.open();
    document.querySelector("ion-menu").open();

  }

  openEnd() {
  	console.log("end menu clicked")
  }

  openCustom() {
  	console.log("custom menu clicked")
  }


  openF7Menu() {
    console.log("custom menu clicked")
    app.panel.open(".panel-left")
  }


}
