import { Controller } from "@hotwired/stimulus"
import { get,post } from '@rails/request.js'

// Connects to data-controller="chart"
export default class extends Controller {
  connect() {
    console.log("connect")
  }

  pippo2(event) {
    event.preventDefault()
    //alert("pippo2")
    // read chart (non serve più)
    //var url_base64 =$("#myChart_print canvas")[0].toDataURL("image/png");
    
    // fill input field (non serve più)
    //$("input[name=string_base64]").val(url_base64)

    //var formEl = $(this.element).closest("form");
    //formEl.submit();

    $(".btn-print").closest("form").submit();

  }

  async pippo() {
  	console.log("pippo")

    var url_base64 =$("#myChart canvas")[0].toDataURL("image/png");

    const response = await post('/report/create_graph_to_image', { 
      body: JSON.stringify({ string_base64: url_base64 }),
      responseKind: "html"
    })

    /*
    $.ajax({
        type:'post', 
        url: '/report/create_graph_to_image', 
        dataType: 'script',
        data: { string_base64: url_base64 } ,
        success: function (e) {
            console.log(e);
        },
        error: function (e) {
            console.log(e);
        }
    });
*/
		



  }
}
