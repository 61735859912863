import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
  	//$(this.element).toast("show")

		var toastElList = [].slice.call(document.querySelectorAll('.toast'))
		var toastList = toastElList.map(function (toastEl) {
		  return new bootstrap.Toast(toastEl, {autohide: false})
		})
		toastList[0].show()

  }
}
