import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    console.log("remote_modal_controller connect")
    this.modalTurboFrame.removeAttribute("src");
    this.modal = new Modal(this.element)
    this.modal.show()
    /*
    $("body").css("overflow", "visible")
    $("body").css("padding-right", "0px")
    $("nav.navbar").css("padding-right", "0px")
    $(".modal").css("width", "auto")
    $(".modal").css("height", "auto")
    */
  }

  hideBeforeRender(event) {

    var pageRedirect = $(this.element).find("form").attr("data-page-redirect")

    if (this.isOpen() && event.detail.success) {
      event.preventDefault()
      console.log("isOpen")
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }


    if (event.detail.success && pageRedirect) {
      console.log("redirect")
      Turbo.visit(event.detail.fetchResponse.response.url)
    }

    
  }

  isOpen() {
    return this.element.classList.contains("show")
  }


  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='remote_modal']");
  }


}