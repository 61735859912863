import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

// Connects to data-controller="employee-worksites--set-position-form"
export default class extends Controller {
  connect() {
  	console.log("set position connect")
  	$("#employees").sortable({
  		update: async function(e, ui) {
  			console.log($(this).sortable('serialize'));
  			var $this=$(this)

    		const response = await patch($this.data("url"), { 
      		body: $this.sortable('serialize'),
      		contentType: 'application/x-www-form-urlencoded',
      		responseKind: "json"
    		})

 	


  		}
  	});
  }
}
