import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shifts"
export default class extends Controller {
  connect() {
  	$('#shifts table').DataTable();
  	console.log('shifts');





  }
}
