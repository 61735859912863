import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {

	static target = [ "entries", "pagination" ]

  connect() {
  	console.log("infinite scroll connect")
  }

  scroll() {
  	var body = document.body,
  		html = document.documentElement

  	var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

  	

  }

}
