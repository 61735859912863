import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pippo2"
export default class extends Controller {
  connect() {
  	console.log("data-turbolinks-preview: "+document.documentElement.hasAttribute("data-turbolinks-preview"))
  	


		if(document.documentElement.hasAttribute("data-turbolinks-preview")) {
      console.log("preview")
    }

    console.log("pippo2")


  }

  disconnect() {
  	console.log("pippo2 disconnect")
  	console.log("data-turbolinks-preview: "+document.documentElement.hasAttribute("data-turbolinks-preview"))
  }
}
