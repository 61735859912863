import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="holidays"
export default class extends Controller {
  connect() {
  	if (!$.fn.DataTable.isDataTable( '#holidays table' )) {
			$('#holidays table').DataTable( {
			    "columnDefs": [ {
			      "targets"  : 'nosort',
			      "orderable": false,
			    }]
			});
		}
  	console.log("holidays");
  }

  teardown() {
  	$('#holidays table').DataTable().destroy();
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  }   

}
