import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="timesheets--list-item"
export default class extends Controller {
  connect() {
  	console.log("timesheets list item connect")
  }

  selectPeople(event) {
    event.preventDefault()
  	console.log("selected people")
  	console.log(event.currentTarget)
  	console.log($(event.currentTarget).attr("data-pid"))

    let pid = $(event.currentTarget).attr("data-pid")
    let start_date = $("#start-date").html()
    let ws = $("#ws").html()    

    if ($(event.currentTarget).closest("body").hasClass("manage")) {

      console.log("manage page")

      get(`/timesheets/manage?ws=${ws}&start_date=${start_date}&pid=${pid}`, {
        responseKind: "turbo-stream"
      })     

      $('#timesheets table').bootstrapTable({}) 

      $(".list-group-item").removeClass("active")
      $(event.currentTarget).addClass("active")       

    } else {


      
      get(`/timesheets?ws=${ws}&start_date=${start_date}&pid=${pid}`, {
        responseKind: "turbo-stream"
      })
      $('#timesheets table').bootstrapTable({}) 

      $(".list-group-item").removeClass("active")
      $(event.currentTarget).addClass("active")

    }




  }  
}
