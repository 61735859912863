import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    //this.element.textContent = "Hello World!"
    console.log("datatables")    

    if (!$.fn.DataTable.isDataTable( '#datatables-container table' )) {
      $('#datatables-container table').DataTable( {
        paging: false,
        "columnDefs": [ {
          "targets"  : 'nosort',
          "orderable": false,
        }]
      });     
    }

    addEventListener('turbo:before-stream-render',this._dt_invalidate)

/*

    if (!$.fn.DataTable.isDataTable( '#example' )) {
			$('#example').DataTable( {
			  "columnDefs": [ {
			    "targets"  : 'nosort',
			    "orderable": false,
			  }]
			});
		}
    */
  }

  teardown() {
  	//('#example').DataTable().destroy();
    $('#datatables-container table').DataTable().destroy();
  }

  dt_invalidate(event){
    console.log("dt_invalidate")
    /*
      var table = document.getElementById(this.tableIdValue)
      var dt  = window.jQuery("#" + this.tableIdValue).DataTable()
      dt.rows().invalidate().draw()//ToDo tighter invalidation strategy
      */
   
  }  

}