import { Controller } from "@hotwired/stimulus"



// Connects to data-controller="pivot"
export default class extends Controller {
  connect() {
  	console.log("pivot connect")
		

		
$("#output").pivotUI(
    [
        {color: "blue", shape: "circle"},
        {color: "red", shape: "triangle"}
    ],
    {
        rows: ["color"],
        cols: ["shape"]
    }
);

  }
}
