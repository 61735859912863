import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-form"
export default class extends Controller {
  connect() {

  }

  submit() {
  	

    event.preventDefault() // Doesn't do anything
    
    var formEl = this.element
    
    // get form data
    var messageView = $(this.element).find("#message_view").val() 

	  var json='{';
	  
	  json+='"message_view":"'+messageView+'"';

	  json+='}';

	  //alert('Settings: '+json);
	  //return;


	  $("#user_settings").val(json);


	  formEl.submit();	    


  }
}
