import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="worksites"
export default class extends Controller {
  connect() {
  	if (!$.fn.DataTable.isDataTable( '#worksites table' )) {
	 		$('#worksites table').DataTable( {
			  "columnDefs": [ {
			    "targets"  : 'nosort',
			    "orderable": false,
			  }]
			});  		
  	}
 	
  	console.log('worksites connect');
  }
  teardown() {
  	$('#worksites table').DataTable().destroy();
  	console.log('worksites teardown');
  }
  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  }
}
