( function( root, factory ) {

    var pluginName = 'gantt';

    if ( typeof define === 'function' && define.amd ) {
        define( [], factory( pluginName ) );
    } else if ( typeof exports === 'object' ) {
        module.exports = factory( pluginName );
    } else {
        root[ pluginName ] = factory( pluginName );
    }
}( this, function( pluginName ) {

    'use strict';

    var defaults = {
        selector: '.yourSelector',
        events: '',
        someDefaultOption: 'foo',
        classToAdd: "new-class-name"
    };

    var jsonData;

    
    
    /**
     * Merge defaults with user options
     * @param {Object} defaults Default settings
     * @param {Object} options User options
     */
    var extend = function( target, options ) {
        var prop, extended = {};
        for ( prop in defaults ) {
            if ( Object.prototype.hasOwnProperty.call( defaults, prop ) ) {
                extended[ prop ] = defaults[ prop ];
            }
        }
        for ( prop in options ) {
            if ( Object.prototype.hasOwnProperty.call( options, prop ) ) {
                extended[ prop ] = options[ prop ];
            }
        }
        return extended;
    };

    /**
     * Helper Functions
     @private
     */
    var privateFunction = function() {
        // Helper function, not directly acessible by instance object
    };

    var _getData = async function(events) {
      fetch(events)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data)
          return data
        });
    }

    var prepareControls = function(selector, data) {
        console.log(data.people)

        var startDate = new Date("2023", "2", "1", "0", "0")
        var viewType = "week"
        var intervals = 7

        data.people.map(function(person){
            console.log(person)
        })


            // crea il contenitore
            let controlContainer = document.createElement("div")
            controlContainer.setAttribute("class", "o_planning_gantt");
            // lo appende al div specificato nel selector
            document.querySelector(selector).appendChild(controlContainer);

      
            let headerSidebar = document.createElement("b")
            headerSidebar.setAttribute("class", "o_gantt_header_sidebar")
            headerSidebar.innerHTML = "Pianificazione";
            controlContainer.appendChild(headerSidebar);
            


            let headerSlots = document.createElement("div")
            headerSlots.setAttribute("class", "o_gantt_header_slots")

            // crea le celle per le date
            var headerCell
            for (var i=0; i<intervals; i++) {
                headerCell = document.createElement("div")
                headerCell.setAttribute("class", "o_gantt_header_cell")
                console.log(format(addDays(startDate, i),"d eee"))
                headerCell.innerHTML = format(addDays(startDate, i),"d eee")
                headerSlots.appendChild(headerCell)
            }

            controlContainer.appendChild(headerSlots);
            
            let rowHeaders = document.createElement("div")
            rowHeaders.setAttribute("class", "o_gantt_row_headers")
            controlContainer.appendChild(rowHeaders);
            //rowHeaders.innerHTML = "row headers";

            let cells = document.createElement("div")
            cells.setAttribute("class", "o_gantt_cells")
            controlContainer.appendChild(cells);
            //cells.innerHTML="celle"            

            var rowHeader
            data.people.map(function(person, index){
                console.log(person)

                //rowHeaders.style.gridTemplateRows = "repeat(" + (index+1)  +", 24px)"

                let rowHeader = document.createElement("div")
                rowHeader.setAttribute("class", "o_gantt_row_header")
                rowHeader.setAttribute("data-resource-id", person.id)
                rowHeader.setAttribute("style", "grid-column:1;grid-row:" + (index+1) + ";" )
                //rowHeader.innerHTML = person.name
                 
                let rowTitle = document.createElement("div")
                rowTitle.setAttribute("class", "o_gantt_row_title")
                rowTitle.innerHTML = person.name
                rowHeader.appendChild(rowTitle)  

                rowHeaders.appendChild(rowHeader)  


                //disegna le celle che conterranno i turni (settimana per il momento)
                for (var i=0; i<intervals; i++) {
                    let cell = document.createElement("div")
                    cell.setAttribute("class", "o_gantt_cell")
                    cell.setAttribute("data-resource-id", person.id)
                    cell.setAttribute("data-col-index", i+1)

                    //cell.style.gridColumn = i+1;
                    //cell.style.gridRow = index+1;   
                    cell.setAttribute("style", "grid-column:" + (i+1) + ";grid-row:" + (index+1) + ";" )                 
                    cells.appendChild(cell);
                }

                //crea i turni (o_gantt_pill_wrapper)
                person.appointments.map(function(appointment){
                    //identifica la colonna (differenza tra lo start del turno e lo start date del gantt)
                    let yyyy = appointment.start.split(" ")[0].split("-")[0]
                    let MM = appointment.start.split(" ")[0].split("-")[1]-1
                    let dd = appointment.start.split(" ")[0].split("-")[2]
                    let hh = appointment.start.split(" ")[1].split(":")[0]
                    let mm = appointment.start.split(" ")[1].split(":")[1]
                    // end
                    let end_yyyy = appointment.end.split(" ")[0].split("-")[0]
                    let end_MM = appointment.end.split(" ")[0].split("-")[1]-1
                    let end_dd = appointment.end.split(" ")[0].split("-")[2]
                    let end_hh = appointment.end.split(" ")[1].split(":")[0]
                    let end_mm = appointment.end.split(" ")[1].split(":")[1]

                    let intToD = differenceInCalendarDays(
                      new Date(yyyy, MM , dd, hh, mm, 0),
                      startDate
                    )
                    let columnIndex = intToD+1
                    //crea il turno
                    if (columnIndex<=intervals) {
                        let pillWrapper = document.createElement("div")
                        pillWrapper.setAttribute("class", "o_gantt_pill_wrapper")
                        //check if position isn't available
                        let checkPos = document.querySelectorAll('.o_gantt_pill_wrapper[style="grid-column:' + columnIndex + ';grid-row:' + (index+1) + ';"]')
                        if (checkPos.length>0) {
                            //alert("posiiozne occupata:" + appointment.start)
                            
                            pillWrapper.setAttribute("style", "grid-column:" + columnIndex + ";grid-row:" + (index+1+checkPos.length) + ";" )
                            //modificare l'altezza della celle contenitore della riga corrente
                            let rowCells = document.querySelectorAll('.o_gantt_cell[data-resource-id="'+person.id+'"]')
                            rowCells.forEach((rowCell) => {
                              console.log(rowCell);
                              rowCell.style.gridRow = (index+1) + " / span 2"
                            });
                            let rowHeader = document.querySelector('.o_gantt_row_header[data-resource-id="'+person.id+'"]')
                            rowHeader.style.gridRow = (index+1) + " / span 2"
                                                      

                        } else {
                            //pillWrapper.style.gridColumn = columnIndex;
                            //pillWrapper.style.gridRow = index+1;
                            pillWrapper.setAttribute("style", "grid-column:" + columnIndex + ";grid-row:" + (index+1) + ";" )
                        }
                            

                        

                        pillWrapper.innerHTML = hh + ":" + mm + "-" + end_hh + ":" + end_mm
                        cells.appendChild(pillWrapper);
                    }
                })

            })








    }


    /**
     * Plugin Object
     * @param {Object} options User options
     * @constructor
     */
    function Plugin( options ) {
        this.options = extend( defaults, options );
        this.init(); // Initialization Code Here
    }

    /**
     * Plugin prototype
     * @public
     * @constructor
     */
    Plugin.prototype = {
        init: function() {
            
            console.log("date-fns:"+format(new Date(2014, 1, 11), 'yyyy-MM-dd'));

            // find all matching DOM elements.
            // makes `.selectors` object available to instance.
            this.selectors = document.querySelectorAll( this.options.selector )
            for ( var i = 0; i < this.selectors.length; i++ ) {
                var selector = this.selectors[ i ]
                    // Do something w/ each matched selector node.
                selector.classList.add( this.options.classToAdd )
                    // do something
            }

            // load data
            fetch(this.options.events)
              .then((response) => response.json())
              .then((data) => {
                console.log(data)
                this.jsonData = data
                prepareControls(this.options.selector, data)
              });

        }, // #! init
        destroy: function() {
            // Remove any event listeners and undo any "init" actions here...
        },
        doSomething: function( someData ) {
            console.log( someData )
        } // #! doSomething
    };
    return Plugin;
} ) );


/**************
    EXAMPLE:
**************/

//// create new Plugin instance
// var myGantt = new gantt({
//     selector: ".gantt",
//     someDefaultOption: 'foo2',
//     classToAdd: "pippi",
// })

//// access public plugin methods
// myGantt.doSomething("Il mio gantt")