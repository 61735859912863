import { Controller } from "@hotwired/stimulus"
import mybutton from "../src/openui5"


// Connects to data-controller="openui5"
export default class extends Controller {
  
  connect() {
  	console.log("ciccio")
  	//mybutton()
		sap.ui.define("Test", [
			"sap/ui/core/mvc/XMLView"
		], function (XMLView) {
			"use strict";
			XMLView.create({viewName: "Quickstart.App"}).then(function (oView) {
				oView.placeAt("content2", "only");
			});
		});
  }

  disconnect() {
  	$(".sapUiView").remove()
  	console.log("disconnect")
  }




}
