import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organization-form"
export default class extends Controller {
  connect() {

		// initialize on single element with jQuery
		var elem = $('.color-input-bg')[0];
		var hueb = new Huebee( elem, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6
		});

		// initialize on single element with jQuery
		var elem2 = $('.color-input-text')[0];
		var hueb = new Huebee( elem2, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      customColors: [ '#212529' ]
		});		

		// initialize on single element with jQuery
		var elem3 = $('.color-input-bg')[1];
		var hueb = new Huebee( elem3, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6
		});

		// initialize on single element with jQuery
		var elem4 = $('.color-input-text')[1];
		var hueb = new Huebee( elem4, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      customColors: [ '#212529' ]
		});	

		// initialize on single element with jQuery
		var elem5 = $('.color-input-bg')[2];
		var hueb = new Huebee( elem5, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6
		});

		// initialize on single element with jQuery
		var elem6 = $('.color-input-text')[2];
		var hueb = new Huebee( elem6, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      customColors: [ '#212529' ]
		});

		// initialize on single element with jQuery
		var elem7 = $('.color-input-bg')[3];
		var hueb = new Huebee( elem7, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6
		});

		// initialize on single element with jQuery
		var elem8 = $('.color-input-text')[3];
		var hueb = new Huebee( elem8, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      customColors: [ '#212529' ]
		});



  }

  submit() {
  	//alert("submit")

    event.preventDefault() // Doesn't do anything
    
    var formEl = this.element
    
    // get form data
    //var setting1 = $(this.element).find("#organization_setting1").val()
    //var setting2 = $(this.element).find("#organization_setting2").val()
    var autoApproveSchedule = $(this.element).find("#organization_auto_approve_schedule").val() 
    var importTimesheetsFromSchedule = $(this.element).find("#organization_import_timesheets_from_schedule").val() 
    var createTimesheetAutomatically = $(this.element).find("#organization_create_timesheet_automatically").val() 

    var userCalendarDisplayOtherPeopleShifts = $(this.element).find("#user_calendar_display_other_people_shifts").val() 

    var sundayShiftColor = $(this.element).find("#organization_sunday_shift_color").val() 
    var sundayShiftTextColor = $(this.element).find("#organization_sunday_shift_text_color").val() 
    var holidayShiftColor = $(this.element).find("#organization_holiday_shift_color").val() 
    var holidayShiftTextColor = $(this.element).find("#organization_holiday_shift_text_color").val() 
    var sundayDayColor = $(this.element).find("#organization_sunday_day_color").val() 
    var sundayDayTextColor = $(this.element).find("#organization_sunday_day_text_color").val() 
    var holidayDayColor = $(this.element).find("#organization_holiday_day_color").val() 
    var holidayDayTextColor = $(this.element).find("#organization_holiday_day_text_color").val() 

    var rvViewColumnWidth = parseInt($(this.element).find("#organization_rv_view_column_width").val())
    if (!Number.isInteger(rvViewColumnWidth)) {
    	alert("Column width is not an integer");
    	return;
    }
    var rvViewShiftLength = $(this.element).find("#organization_rv_view_shift_length").val() 
    var rvViewShiftTotalCol = $(this.element).find("#organization_rv_view_shift_total_col").val() 
    var rvViewLocationTotalCol = $(this.element).find("#organization_rv_view_location_total_col").val() 
    var rvViewDifferenceCol = $(this.element).find("#organization_rv_view_difference_col").val() 
    var rvViewCapacityCol = $(this.element).find("#organization_rv_view_capacity_col").val() 

    var rvViewCapacityColsWidth = parseInt($(this.element).find("#organization_rv_view_capacity_cols_width").val())
    if (!Number.isInteger(rvViewCapacityColsWidth)) {
    	alert("Capacity columns width is not an integer");
    	return;
    }    
    var rvViewDayColWidth = parseInt($(this.element).find("#organization_rv_view_day_col_width").val())
    if (!Number.isInteger(rvViewDayColWidth)) {
    	alert("Day column width is not an integer");
    	return;
    }    

    /* user interface */
    var userInterfaceTheme = $(this.element).find("#organization_user_interface_theme").val() 


	  var json='{';
	  
	  //setting1
	  //json+='"setting1":'+setting1+',';
	  
	  //setting2
	  //json+='"setting2":'+setting2+',';

	  //auto_approve_schedule
	  json+='"auto_approve_schedule":'+autoApproveSchedule+',';	  

	  //import_timesheets_from_schedule
	  json+='"import_timesheets_from_schedule":'+importTimesheetsFromSchedule+',';	

	  //create_timesheet_automatically
	  json+='"create_timesheet_automatically":'+createTimesheetAutomatically+',';		  

	  // user calendar display other people shifts
	  json+='"user_calendar_display_other_people_shifts":'+userCalendarDisplayOtherPeopleShifts+',';
	  
	  // shift sunday and holidy colors
	  json+='"sunday_shift_color":"'+sundayShiftColor+'",';	
	  json+='"sunday_shift_text_color":"'+sundayShiftTextColor+'",';	
	  json+='"holiday_shift_color":"'+holidayShiftColor+'",';	
	  json+='"holiday_shift_text_color":"'+holidayShiftTextColor+'",';	

	  // day sunday and holidy colors
	  json+='"sunday_day_color":"'+sundayDayColor+'",';	
	  json+='"sunday_day_text_color":"'+sundayDayTextColor+'",';	
	  json+='"holiday_day_color":"'+holidayDayColor+'",';	
	  json+='"holiday_day_text_color":"'+holidayDayTextColor+'",';	

	  // resource view vertical settings
	  json+='"rv_view_column_width":'+rvViewColumnWidth+',';
	  json+='"rv_view_shift_length":'+rvViewShiftLength+',';
	  json+='"rv_view_shift_total_col":'+rvViewShiftTotalCol+',';
	  json+='"rv_view_location_total_col":'+rvViewLocationTotalCol+',';
	  json+='"rv_view_difference_col":'+rvViewDifferenceCol+',';
	  json+='"rv_view_capacity_col":'+rvViewCapacityCol+',';

	  json+='"rv_view_capacity_cols_width":'+rvViewCapacityColsWidth+',';
	  json+='"rv_view_day_col_width":'+rvViewDayColWidth+',';

	  json+='"user_interface_theme":"'+userInterfaceTheme+'"';

	  json+='}';

	  //alert('Settings: '+json);
	  //return;


	  $("#organization_settings").val(json);

	  formEl.submit();


  }
}
