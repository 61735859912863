import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="toolbar"
export default class extends Controller {



  connect() {

  	if ($("body").hasClass("posts") && $("body").hasClass("index_new")) {
  		$(document).on('click','.btn-delete-selected', async function(e){
  			//alert("delete selected");
  			e.preventDefault();
  			var filter = $(e.target).attr("data-filter");
  			//alert(filter);
  			//return;
  			var selection = $("#posts-table").bootstrapTable('getSelections');
  			console.log(selection);  	
  			
  			var pids = [];
		    for (i=0;i<selection.length;i++) { 
		    	console.log(selection[i][1]) 
		    	//delete post
		    	pids.push(selection[i][1]);
		    }

		    console.log('pids' + pids);

		    let confirmDelete = confirm("Are you sure?");

				if (confirmDelete) {
	    		const response = await get(`/posts/delete_selected?pids=${pids}&filter=${filter}`, {
	      		responseKind: "turbo-stream"
	    		})
	    	}		    

  		});
  	}

  	if ($("body").hasClass("posts") && $("body").hasClass("manage")) {
  		$(document).on('click','.btn-delete-posts', async function(e){
  			e.preventDefault();
  			//alert("elimina messaggi");
  			var selection = $("#posts-table").bootstrapTable('getSelections');
  			console.log(selection);

		    var pids = [];
		    for (i=0;i<selection.length;i++) { 
		    	console.log(selection[i][1]) 
		    	//delete post
		    	pids.push(selection[i][1]);
		    }

		    console.log('pids' + pids);

		    let confirmDelete = confirm("Are you sure?");

				if (confirmDelete) {
	    		const response = await get(`/posts/delete_multiple?pids=${pids}`, {
	      		responseKind: "turbo-stream"
	    		})
	    	}


  		});
  	}

  	if ($("body").hasClass("payroll_payslips")) {
  		$(document).on('click','.btn-person-notes',function(e){
  			e.preventDefault()
				const toastLiveExample = document.getElementById('liveToast')
				const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample, {autohide:false})
				toastBootstrap.show()
  		});
  	}


		if ($("body").hasClass("report") && $("body").hasClass("product_warehouse_operations")) {

			var baseUrl="/report/product_warehouse_operations"
					
			// product select2
			$('select.products').select2({
				theme: 'bootstrap-5'
			}).on('select2:select', function(e) {
				var data = e.params.data
				var productId = data.id			
				//alert("prodotto selezionato:" + productId)

				var startDate = $(".start-date").val();
				var endDate = $(".end-date").val();
			
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)

			});

			//start date
			$(document).on('blur','.start-date',function(e){
				var startDate = $(e.target).val();
				var endDate = $(".end-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});

			//end date
			$(document).on('blur','.end-date',function(e){
				var endDate = $(e.target).val();
				var startDate = $(".start-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});

		}

		if ($("body").hasClass("report") && $("body").hasClass("product_location_monthly")) {
			var baseUrl="/report/product_location_monthly"

			// product select2
			$('select.products').select2({
				theme: 'bootstrap-5'
			}).on('select2:select', function(e) {
				var data = e.params.data
				var productId = data.id			
				//alert("prodotto selezionato:" + productId)

				var startDate = $(".start-date").val();
				var endDate = $(".end-date").val();
			
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)

			});

			//start date
			$(document).on('blur','.start-date',function(e){
				var startDate = $(e.target).val();
				var endDate = $(".end-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});

			//end date
			$(document).on('blur','.end-date',function(e){
				var endDate = $(e.target).val();
				var startDate = $(".start-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});
			


		}

		if ( $("body").hasClass("report") && ( $("body").hasClass("quantities_transferred_by_product") || $("body").hasClass("quantities_transferred_by_product_check") ) ) {

			var baseUrl="/report/quantities_transferred_by_product"
					
			// product select2
			/*
			$('select.products').select2({
				theme: 'bootstrap-5'
			}).on('select2:select', function(e) {
				var data = e.params.data
				var productId = data.id			
				//alert("prodotto selezionato:" + productId)

				var startDate = $(".start-date").val();
				var endDate = $(".end-date").val();
			
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)

			});
			*/
//alert("pippo")


			if (!$('select.product-types').hasClass("select2-hidden-accessible")) {
			    // Select2 has been initialized
			    //alert("non inizializzato")

				// product types select2
				$('select.product-types').select2({
					theme: 'bootstrap-5',
					placeholder: "Select a type"
				}).on('select2:select', function(e) {
					var selected = $('select.product-types').select2("val");
					var startDate = $(".start-date").val();
					var endDate = $(".end-date").val();
					var url = baseUrl + "?ptype=" + selected + "&start=" + startDate + "&end=" + endDate;
					console.log(url)
					Turbo.visit(url)

				}).on('select2:unselect', function(e) {
					var selected = $('select.product-types').select2("val");
					var startDate = $(".start-date").val();
					var endDate = $(".end-date").val();
					var url = baseUrl + "?ptype=" + selected + "&start=" + startDate + "&end=" + endDate;
					console.log(url)
					Turbo.visit(url)			
				});


			}

			if (!$('select.warehouse-locations').hasClass("select2-hidden-accessible")) {
				$('select.warehouse-locations').select2({
					theme: 'bootstrap-5',
					placeholder: "Select locations"		
				}).on('select2:select', function(e) {
					var locations = $('select.warehouse-locations').select2("val");
					var selected = $('select.product-types').select2("val");
					var startDate = $(".start-date").val();
					var endDate = $(".end-date").val();
					var url = baseUrl + "?ptype=" + selected + "&start=" + startDate + "&end=" + endDate + "&l=" + locations;
					console.log(url)
					Turbo.visit(url)								
				}).on('select2:unselect', function(e) {
					var locations = $('select.warehouse-locations').select2("val");
					var selected = $('select.product-types').select2("val");
					var startDate = $(".start-date").val();
					var endDate = $(".end-date").val();
					var url = baseUrl + "?ptype=" + selected + "&start=" + startDate + "&end=" + endDate + "&l=" + locations;
					console.log(url)
					Turbo.visit(url)			
				});
			}



	    var url = document.location.href;
	    //alert(url)
	    var qs = url.substring(url.indexOf('?') + 1).split('&');
	    //alert(qs)
	    var values="", location_values=""
	    for(var i = 0; i < qs.length; i++){
	      qs[i] = qs[i].split('=');
	      //alert(qs[i][0])
	      if (qs[i][0]=="ptype") {
	      	values = qs[i][1].split(',');
	      }
	      if (qs[i][0]=="l") {
	      	location_values = qs[i][1].split(',');
	      }	      
	    }


			$('select.product-types').val(values).trigger('change');
			$('select.warehouse-locations').val(location_values).trigger('change');

			//start date
			$(document).on('blur','.start-date',function(e){
				var startDate = $(e.target).val();
				var endDate = $(".end-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});

			//end date
			$(document).on('blur','.end-date',function(e){
				var endDate = $(e.target).val();
				var startDate = $(".start-date").val();
				var productId = $('select.products').val();
				var url = baseUrl + "?pid=" + productId + "&start=" + startDate + "&end=" + endDate;
				console.log(url)
				Turbo.visit(url)
			});

		}

  }

  disconnect() {
  	$(document).off('click','.btn-delete-selected')
  }

}
