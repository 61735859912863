import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  connect() {
  	//alert("alert connect")

				const toastLiveExample = document.getElementById('alert-toast')
				const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample, {autohide:true})
				toastBootstrap.show()

  }
}
