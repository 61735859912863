import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee"
export default class extends Controller {

  connect() {
		$('#employee_worksite_ids').select2({
			theme: 'bootstrap-5'
		});
		$('#employee_alias_worksite_ids').select2({
			theme: 'bootstrap-5'
		});
  }

  uploadDocuments() {
  	var $formEl = $(this.element).find("#upload-employee-documents")
  	var formEl = document.getElementById("upload-employee-documents")
  	formEl.requestSubmit()
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  }  

}
