(function (root, factory) {
	if ( typeof define === 'function' && define.amd ) {
		define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.myPlugin = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

	'use strict';

	//
	// Variables
	//

	var defaultOptions = {
		container: ".sliderContainer",
		slidesClass: ".singleSlide",
		nextButton: ".nextSlide",
		previousButton: ".previousSlide"
	}


	//
	// Methods
	//

	/**
	 * The plugin constructor
	 * @param {String} selector The selector to use for forms to be validated
	 * @param {Object} options  User settings [optional]
	 */
	var Constructor = function (selector, options) {

		//
		// Variables
		//

		var publicAPIs = {};
		var settings;

		var _this = this;
		var slides = [];
		var currentSlideIndex = 0;		


		//
		// Methods
		//

    /**
     * A private method
     * @private
     */
    var somePrivateMethod = function () {
        // Code goes here...
    };


		var prepareControls = function() {
			const nextButton = document.createElement("button");
			const previousButton = document.createElement("button");

			nextButton.setAttribute("class", "next");
			nextButton.innerHTML = "Next";

			previousButton.setAttribute("class", "prev");
			previousButton.innerHTML = "Prev";	

			let controlContainer = document.createElement("div");
			
			controlContainer.setAttribute("class", "too-slide-control-container");
			
			controlContainer.appendChild(previousButton);
			controlContainer.appendChild(nextButton);

			document.querySelector(options.container).appendChild(controlContainer);

      nextButton.addEventListener("click", function(){
        next();
      })
      
      previousButton.addEventListener("click", function(){
      	previous();
      })			

		}

    var next = function(){  
      goToSlide(currentSlideIndex+1);
    }

    var previous = function(){  
      goToSlide(currentSlideIndex-1);
    }

    var goToSlide = function(index){
    		console.log(index);
        hideOtherSlides();
        if (index > slides.length - 1){    
          index = 0;
        }
        if (index < 0){
          index = slides.length - 1;
        }
        
        slides[index].style = "display:block";
        currentSlideIndex = index;
    }

    var hideOtherSlides = function(){
      document.querySelectorAll(options.slidesClass).forEach((slide, index) => {
      	slides[index].style = "display:none";
      })
    }    



    /**
     * A public method
     */
    publicAPIs.doSomething = function () {
        somePrivateMethod();
        // Code goes here...
    };





		/**
		 * public method test
		 */
		publicAPIs.test = function () {
			console.log("test")
		};

		/**
		 * Instantiate a new instance of the plugin
		 */
		var init = function () {

      // Merge user options with defaults
      options = { ...defaultOptions, ...options }

      document.querySelector(options.container).className += " too-slide-slider-container";
      document.querySelectorAll(options.slidesClass).forEach((slide, index) => {
      	console.log(slide);
       	slides[index] = slide;
        slides[index].style = "display:none";
        slides[index].className += " too-slide-single-slide too-slide-fade";
      });
        
      goToSlide(0)
      prepareControls();

		};

		//
		// Inits & Event Listeners
		//

		init();
		return publicAPIs;

	};


	//
	// Return the constructor
	//

	return Constructor;

});