import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-picker"
export default class extends Controller {
  connect() {

		// initialize on single element with jQuery
		var elem = $('.color-input')[0];
		var hueb = new Huebee( elem, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      notation: 'hex'
		});

		// initialize on single element with jQuery
		var elem2 = $('.color-input')[1];
		var hueb = new Huebee( elem2, {
		  // options
		  saturations: 1,
			className: "huebee--custom-grid-size",
      hues: 12,
      shades: 6,
      notation: 'hex',
      customColors: [ '#212529' ]
		});		

  }
}
