import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="evo-calendar"
export default class extends Controller {
  connect() {
  	console.log("connect evo")

    // evo-calendar
 
    


  }
}
