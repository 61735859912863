import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar"
export default class extends Controller {
  connect() {

	  var calendarEl = document.getElementById('fullcalendar');

	  var calendar = new FullCalendar.Calendar(calendarEl, {

      initialView: 'dayGridWeek',

	    events: '/main/calendar.json',
	    headerToolbar: {
	      left  : 'prev,next today',
	      center: 'title',
	      right : 'dayGridMonth,dayGridWeek,dayGridDay,listMonth'
	    },

	    themeSystem: 'bootstrap5',
	    locale: 'it',

	    editable  : true,
	    droppable : true, // this allows things to be dropped onto the calendar !!!
	    //aspectRatio: 1.35,
	    height: "auto",
	    //dayMaxEvents: true,
	    displayEventEnd: true,
	    eventTimeFormat: {
	      hour: 'numeric',
	      minute: '2-digit',
	      hour12: false
	    },
	    slotEventOverlap: false,
	    firstDay: 1,   

      eventClick: function(info) {
        //alert("click")
      },


/*
      windowResize: function(arg) {
        //alert('The calendar has adjusted to a window resize. Current view: ' + arg.view.type);
        
        if ($(window).width() < 765) {
          $(".fc-toolbar-title").closest(".fc-toolbar-chunk").css("order",1)
          $(".fc-prev-button").closest(".fc-toolbar-chunk").css("order",2)
          $(".fc-dayGridWeek-button").closest(".fc-toolbar-chunk").css("order",3)
        } else {
          $(".fc-toolbar-title").closest(".fc-toolbar-chunk").css("order",2)
          $(".fc-prev-button").closest(".fc-toolbar-chunk").css("order",1)
          $(".fc-dayGridWeek-button").closest(".fc-toolbar-chunk").css("order",3)          
        }
      },
*/
      eventDidMount: function(info) {
      	//alert(info.event.extendedProps.type);
        info.el.setAttribute("data-event-id", info.event.id);  
        info.el.setAttribute("data-turbo-frame", "remote_modal");  
        // https://github.com/fullcalendar/fullcalendar/issues/450
        
        /*
        if (info.event.extendedProps.type == "Event") {
        	$(info.el).find(".fc-event-time").remove();
        }
        */

        /*
        if(info.event.extendedProps.notes != '' && typeof info.event.extendedProps.notes  !== "undefined") {
        	$(info.el).find(".fc-event-title").append("<br/>"+info.event.extendedProps.notes); 
        }
        */
        if(info.event.extendedProps.notes != '' && typeof info.event.extendedProps.notes  !== "undefined" && info.event.extendedProps.notes != null) {
        	$(info.el).find(".fc-event-title-container").append('<div class="notes">'+info.event.extendedProps.notes+'ddddd</div>'); 
        }        
      },   	    

	    datesSet: function(info) {
	      if (calendar.view.type=="dayGridMonth") {
	        calendar.setOption('height', 'auto');
	      } else {
	        calendar.setOption('height', null);
	      }

	    },

			eventClassNames: function(arg) {
			  if (arg.event.extendedProps.type == "Event") {
			    return [ 'event' ]
			  } else {
			    return [ 'shift' ]
			  }
			},   

      eventDrop: function(info) {
        alert(info.event.title + " was dropped on " + info.event.start.toISOString());
        
        /*
        console.log(info.event.start.toISOString());
        console.log(info.event.end.toISOString());
        console.log(info.event.id);
        var startTime = moment(info.event.start).format("YYYY-MM-DD HH:mm");
        var endTime = moment(info.event.end).format("YYYY-MM-DD HH:mm");

        //if (!confirm("Are you sure about this change?")) {
          //info.revert();
        //} else {
          $.ajax({
            type: "POST",
            url: "/events/" + info.event.id + "/move_event",
            data: "start=" + startTime + "&end=" + endTime,
            dataType: 'script',
            success: function(resp){
              console.log(resp)
            }
          });
        //}
        */
      },



	  });

	  calendar.render();






  }
}
