import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openui5--test"
export default class extends Controller {
  connect() {
  	console.log("openui5--test connect")

		sap.ui.define("Test", [
			"sap/ui/core/mvc/XMLView"
		], function (XMLView) {
			"use strict";
			XMLView.create({viewName: "Quickstart.openui5.test"}).then(function (oView) {
				oView.placeAt("content", "only");
			});
		});

  }
}
