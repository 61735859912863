import { Controller } from "@hotwired/stimulus"
import { get,post } from '@rails/request.js'

// Connects to data-controller="planning"
export default class extends Controller {
  
  connect() {
		


		sap.ui.require([
			"sap/ui/core/mvc/XMLView"
		], function (XMLView) {
			"use strict";
			XMLView.create({viewName: "Quickstart.shifts.Planning"}).then(function (oView) {
				console.log(oView)
				oView.placeAt("content3", "only");
			});
		});


        $( ".sapUiCalendarRowAppsInt" ).click(function() {
            alert( "Hello World!" );
        });




/*

	//test (load external page body - può servirmi per creare applicazione esterna openui5 e caricarne le pagine via fetch)
    //NOTA: non funziona con openui5 (funziona con altre pagine)
	fetch('https://shiftt.local/assets/dist', {
     
     })
     .then(function(response) {
     // When the page is loaded convert it to text
     return response.text()
     })
    .then(function(htmlString) {
     // Initialize the DOM parser
     console.log(htmlString)
     var parser = new DOMParser();
     // Parse the text
     var doc = parser.parseFromString(htmlString, "text/html");      
     // You can now even select part of that html as you would in the regular DOM 
     // Example:
     // var docArticle = doc.querySelector('article').innerHTML;
     
     var docBody = doc.querySelector('body').innerHTML;
     console.log(docBody);
     $("#content_").html(docBody)
     })
     .catch(function(err) {  
     console.log('Failed to fetch page: ', err);  
     });

*/
  }
  

  refreshPlanningCalendar() {
  	console.log("refreshPlanningCalendar")
		sap.ui.require([
			"sap/ui/core/mvc/XMLView"
		], function (XMLView) {
			"use strict";
			XMLView.create({viewName: "Quickstart.shifts.Planning"}).then(function (oView) {
				oView.placeAt("content3", "only");
			});
		});
  }

}
