import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timesheets"
export default class extends Controller {
  
  connect() {

		$('.timesheets.index select.worksites').select2({
			theme: 'bootstrap-5'
		}).on('select2:select', function(e) {
			var data = e.params.data
			var placeId = data.id
			window.location = "/timesheets?ws=" + placeId			
		});

		$('.timesheets.manage select.worksites').select2({
			theme: 'bootstrap-5'
		}).on('select2:select', function(e) {
			var data = e.params.data
			var placeId = data.id
			window.location = "/timesheets/manage?ws=" + placeId			
		});		

  }
}
