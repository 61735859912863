import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="worksite"
export default class extends Controller {

  connect() {

  }

  uploadDocuments() {
  	var $formEl = $(this.element).find("#upload-worksite-documents")
  	var formEl = document.getElementById("upload-worksite-documents")
  	formEl.requestSubmit()
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  } 







}
