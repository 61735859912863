import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile--posts"
export default class extends Controller {

	static targets = [ "calendar" ]
  
  connect() {
  	console.log("connect posts")

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var weekLater = new Date().setDate(today.getDate() + 7);  	

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();    

    this.events = [
      {
        date: new Date(year, month, day),
        hours: 12,
        minutes: 30,
        title: 'Meeting with Vladimir',
        color: '#2196f3',
      },
      {
        date: new Date(year, month, day),
        hours: 18,
        minutes: 0,
        title: 'Shopping',
        color: '#4caf50',
      },
      {
        date: new Date(year, month, day),
        hours: 21,
        minutes: 0,
        title: 'Gym',
        color: '#e91e63',
      },
      {
        date: new Date(year, month, day + 2),
        hours: 16,
        minutes: 0,
        title: 'Pay loan',
        color: '#2196f3',
      },
      {
        date: new Date(year, month, day + 2),
        hours: 21,
        minutes: 0,
        title: 'Gym',
        color: '#ff9800',
      },
    ];

    let ciccio=this

		this.calendar = app.calendar.create({
    	containerEl: '#demo-calendar-inline-container',
    	toolbar: false,
			events: this.events,

      on: {

				init: function (calendar) {
					var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          $('.navbar-calendar-title').text(monthNames[calendar.currentMonth] + ', ' + calendar.currentYear);
          app.navbar.size(app.navbar.getElByPage("posts"));
          calendar.$el.addClass('no-safe-area-right');

          // da errore -> risolvere
          ciccio.renderEvents(calendar);
 
         

        },

        monthYearChangeStart: function (calendar) {
        	var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          $('.navbar-calendar-title').text(monthNames[calendar.currentMonth] + ', ' + calendar.currentYear);
          app.navbar.size(app.navbar.getElByPage("posts"));
        },


        change: function (calendar) {
          ciccio.renderEvents(calendar);
          console.log("change")
        },
      }
	  });

  }

  calendarTargetConnected(element) {
    console.log("calendar connected")
		//this.renderEvents(this.calendar);
		console.log(element)
  }  

  renderEvents(calendar) {

    

    if (calendar.value) {
    	var currentDate = calendar.value[0];
    	alert("pippo")
    } else {
    	//var currentDate = new Date();
    	var today = new Date();
    	var currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    }

    console.log("current date: " + currentDate)
    //alert(currentDate)
    var currentEvents = this.events
      .filter(function (event) {
        return (
          event.date.getTime() >= currentDate.getTime() &&
          event.date.getTime() < currentDate.getTime() + 24 * 60 * 60 * 1000
        );
      });

    let eventItems = [];
    if (currentEvents.length) {
      currentEvents.forEach(function (event) {
        const hours = event.hours;
        let minutes = event.minutes;
        if (minutes < 10) minutes = `0${minutes}`;
        eventItems.push({
          title: event.title,
          time: `${hours}:${minutes}`,
          color: event.color,
        });
      });
    }
    this.updateCurrentEvents(eventItems);
  };


  updateCurrentEvents(eventItems) {
  	console.log("update current events")
  	console.log(eventItems.length)
  	$("#calendar-events ul").html("")
  	eventItems.forEach(function (event) {
  		var html = '<li class="item-content">';
  		html+='<div class="event-color" style="background-color:'+event.color+'"></div>';
  		html+='<div class="item-inner">';
  		html+='<div class="item-title">'+event.title+'</div>';
  		html+='<div class="item-after">'+event.time+'</div>';
  		html+='</div>';
  		html+='</li>'
  		$("#calendar-events ul").append(html)
  	});
  	
  };  


}
