import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="dashboard"
export default class extends Controller {

  connect() {

    console.log("dashboard connect")

    var calendarEl = document.getElementById('calendar');

    var calendar = new FullCalendar.Calendar(calendarEl, {
      events: '/main/calendar.json',
      headerToolbar: {
        left  : 'prev,next today',
        center: 'title',
        right : 'dayGridMonth,dayGridWeek,dayGridDay'
      },

      themeSystem: 'bootstrap5',

      editable  : true,
      droppable : true, // this allows things to be dropped onto the calendar !!!
      //aspectRatio: 1.35,
      height: "auto",
      //dayMaxEvents: true,
      displayEventEnd: true,
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: false
      },
      slotEventOverlap: false,
      firstDay: 1,   

      datesSet: function(info) {
        if (calendar.view.type=="dayGridMonth") {
          calendar.setOption('height', 'auto');
        } else {
          calendar.setOption('height', null);
        }
      },           
        
    });

    calendar.render();


    // evo-calendar
    $("#evo-calendar").evoCalendar();
    

  }    
	




}
