import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {
  connect() {
  	console.log("connected to dropxzone controller")

  	var $dropZone;

		// Initialize Dropzone
		$dropZone = $("#new-post").dropzone({
		  // The configuration we've talked about above
		  autoProcessQueue: false,
		  uploadMultiple: true,
		  parallelUploads: 10,
		  maxFiles: 100,
		  paramName: "post[files]",
		  previewsContainer: ".previews",
		  clickable: ".previews",
		  dictDefaultMessage: "",
		  timeout: 120000,
		  maxThumbnailFilesize: 30,
		  acceptedFiles: "image/jpeg,image/png,image/gif,application/pdf",
		  addRemoveLinks: true,

		  // The setting up of the dropzone
		  init: function() {


				this.on('addedfile', function(file){
	        if(this.files.length > 0) {
	        	$(".drop-message").hide();
	        }
	      });
				this.on('removedfile', function(file){
	        if(this.files.length == 0) {
	        	$(".drop-message").show();
	        }
	      });

		    this.on("successmultiple", function(files, response) {
		      // Gets triggered when the files have successfully been sent.
		      // Redirect user or notify of success.
		      window.location = "/posts/";
		    });
		    this.on("errormultiple", function(files, response) {
		      // Gets triggered when there was an error sending the files.
		      // Maybe show form again, and notify user of error
		    });
		  }
		});

		$(document).on('click','#new-post .btn-save',function(e){

			if ($("#new-post")[0].dropzone.getQueuedFiles().length === 0) {
				console.log("no files attached")
			} else {
      	e.preventDefault();
      	e.stopPropagation();      
      	$dropZone[0].dropzone.processQueue();			
      }
		});

  }
}
