import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-shifts-form"
export default class extends Controller {
  connect() {
  }

  refreshCalendars() {
    console.log("refreshCalendars")
    window.dispatchEvent(new CustomEvent("refreshFC"));
    //window.dispatchEvent(new CustomEvent("refreshPlanningCalendar"));
  }
    
}
