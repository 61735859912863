import { Controller } from "@hotwired/stimulus"

import React from 'react';
import { render } from 'react-dom';
import Clock from '../components/clock.jsx';

// Connects to data-controller="clock"
export default class extends Controller {
  connect() {
  	render(<Clock />, this.element);
  }
}
