import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-types"
export default class extends Controller {
  connect() {
  	if (!$.fn.DataTable.isDataTable( '#event-types table' )) {
			$('#event-types table').DataTable( {
			  "columnDefs": [ {
			    "targets"  : 'nosort',
			    "orderable": false,
			  }]
			});
		}
  }

  teardown() {
  	$('#event-types table').DataTable().destroy();
  }

  delete(event) {
    let confirmed = confirm("Are you sure?")
    if (!confirmed) {
      event.preventDefault()
    }
  }  

}
