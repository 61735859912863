import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

// Connects to data-controller="shift-form"
export default class extends Controller {

  static targets = [ "copytodays", "other" ]

  refreshCalendar() {
    window.dispatchEvent(new CustomEvent("refreshFC"));
    window.dispatchEvent(new CustomEvent("refreshPlanningCalendar"));
  }

  connect() {
    //get start date
    var startDate = $(".simple-calendar").attr("data-start");    
    $('.datepicker').datepicker({
      multidate: true,
      format: "yyyy-mm-dd",
      startDate: startDate,
      language: "it-IT"
    });
    $(".context-menu").remove()
  }


  async submit(event) {

  		//console.log($(this.element).attr("data-check-contract"))

  		if ($(this.element).attr("data-check-contract")=="false") {
  			return;
  		}
   
      event.preventDefault() // Doesn't do anything
      //console.log("cancel submit") // prints out
    
      //console.log($(this.element).find("#shift_s_time").val())

      var formEl = this.element
      var $formEl = $(this.element)

      // get form data
      var worksiteId = $(this.element).find("#shift_worksite_id").val()
      var employeeId = $(this.element).find("#shift_employee_id").val()
      var date = $(this.element).find("#shift_date").val()
      var startTime = $(this.element).find("#shift_s_time").val()
      var endTime = $(this.element).find("#shift_e_time").val()
      var repeatUntil = $(this.element).find("#shift_repeat_until").val()
      var repeatDays = ($(this.element).find("#shift_repeat_sun").is(":checked") ? 1 : 0) + "," 
        + ($(this.element).find("#shift_repeat_mon").is(":checked") ? 1 : 0) + ","
        + ($(this.element).find("#shift_repeat_tue").is(":checked") ? 1 : 0) + "," 
        + ($(this.element).find("#shift_repeat_wed").is(":checked") ? 1 : 0) + "," 
        + ($(this.element).find("#shift_repeat_thu").is(":checked") ? 1 : 0) + "," 
        + ($(this.element).find("#shift_repeat_fri").is(":checked") ? 1 : 0) + "," 
        + ($(this.element).find("#shift_repeat_sat").is(":checked") ? 1 : 0)

      //console.log(repeatUntil)
      //console.log(repeatDays)

      //ajax call to check emp monthly hours
	    const response = await get(`/shifts/check_employee_monthly_hours?eid=${employeeId}&date=${date}&stime=${startTime}&etime=${endTime}&repd=${repeatDays}&repu=${repeatUntil}`, {
	      responseKind: "json"
	    })

	    if (response.ok) {
	    	const res = await response.json
	    	//console.log(JSON.stringify(await response.json))
	    	//console.log(res.available)

	    	if (!res.available) {

          var total_employee_shifts_hours = Math.floor(res.total_employee_shifts / 60);
          var total_employee_shifts_minutes = res.total_employee_shifts % 60;
          var current_contract_max_hours = Math.floor(res.current_contract_max_minutes / 60);
          var current_contract_max_minutes = res.current_contract_max_minutes % 60;          
			
          Swal.fire({
            title: i18n.t('hours_in_eccess_title'),
            html: i18n.t('hours_in_eccess_msg1') + total_employee_shifts_hours + ':' + total_employee_shifts_minutes + i18n.t('hours_in_eccess_msg2') + current_contract_max_hours + ':' + current_contract_max_minutes,
            icon: 'warning',
            heightAuto: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('hours_in_eccess_button'),
            cancelButtonText: i18n.t('cancel')
          }).then(function(result) {
            if (result.isConfirmed) {
            	// submit form
            	console.log("add record")
            	$formEl.attr("data-check-contract",false)
            	formEl.requestSubmit()
              // update monthly hours info
              $("#shifts-hours").html(total_employee_shifts_hours + ":" + total_employee_shifts_minutes)
            }
          });

	    	} else {
	    		$formEl.attr("data-check-contract",false)
	    		formEl.requestSubmit()
	    	}


	    }
    
  }

  async copy() {
    

    //get form values   
    var date = $(this.element).find("#shift_date").val()
    var startTime = $(this.element).find("#shift_s_time").val()
    var endTime = $(this.element).find("#shift_e_time").val()
    var peopleId = $(this.element).find("#shift_employee_id").val()
    var locationId = $(this.element).find("#shift_worksite_id").val()
    var notes = $(this.element).find("#shift_notes").val()
    var color = $(this.element).find("#shift_color").val()
    var textColor = $(this.element).find("#shift_text_color").val()

    var copyPeopleId = $(this.element).find("#shift_copy_employee_id").val()
    if (copyPeopleId != "") {
      peopleId=copyPeopleId
    }

    var dates = this.copytodaysTarget.value

    //ajax call to check emp monthly hours
    const check = await get(`/shifts/check_before_copy?eid=${peopleId}&date=${date}&stime=${startTime}&etime=${endTime}&dates=${dates}`, {
      responseKind: "json"
    })

    if (check.ok) {
      const checkRes = await check.json
      //console.log(JSON.stringify(await response.json))
      console.log(checkRes.available)

      if (!checkRes.available) {

        var total_employee_shifts_hours = Math.floor(checkRes.total_employee_shifts / 60);
        var total_employee_shifts_minutes = checkRes.total_employee_shifts % 60;
        var current_contract_max_hours = Math.floor(checkRes.current_contract_max_minutes / 60);
        var current_contract_max_minutes = checkRes.current_contract_max_minutes % 60;          
    
        Swal.fire({
          title: i18n.t('hours_in_eccess_title'),
          html: i18n.t('hours_in_eccess_msg1') + total_employee_shifts_hours + ':' + total_employee_shifts_minutes + i18n.t('hours_in_eccess_msg2') + current_contract_max_hours + ':' + current_contract_max_minutes,
          icon: 'warning',
          heightAuto: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: i18n.t('hours_in_eccess_button'),
          cancelButtonText: i18n.t('cancel')
        }).then(async function(result) {
          if (result.isConfirmed) {
            // submit form
            console.log("copy shift")
            const response = await post('/shifts/copy', { 
              body: JSON.stringify({ lid: locationId, pid: peopleId, dates: dates, stime: startTime, etime: endTime, notes: notes, color: color, textcolor: textColor }),
              responseKind: "turbo-stream"
            })
            window.dispatchEvent(new CustomEvent("refreshFC"));
          }
        });

      } else {
        const response = await post('/shifts/copy', { 
          body: JSON.stringify({ lid: locationId, pid: peopleId, dates: dates, stime: startTime, etime: endTime, notes: notes, color: color, textcolor: textColor }),
          responseKind: "turbo-stream"
        })
        window.dispatchEvent(new CustomEvent("refreshFC"));
      }


    }

/*

    const response = await post('/shifts/copy', { 
      body: JSON.stringify({ lid: locationId, pid: peopleId, dates: dates, stime: startTime, etime: endTime }),
      responseKind: "turbo-stream"
    })
    if (response.ok) {
      console.log(response)
    }    

*/

  }  


}
