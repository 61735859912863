import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="worksite-rule-exception-form"
export default class extends Controller {
  connect() {
  	//alert("mulit")

    $('.datepicker').datepicker({
      multidate: true,
      format: "yyyy-mm-dd",
      language: "it-IT"
    });

  }
}
