import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {

  connect() {

  	var $thisElement=$(this.element)

		// person selection
		$('select.people').select2({
			theme: 'bootstrap-5'
		}).on('select2:select', function(e) {


			var data = e.params.data
			var peopleId = data.id
			var url = $thisElement.attr("data-url") + "&pid=" + peopleId;
			

			if ($("body").hasClass("report")) {
				//window.location = url
				// Turbo.visit not execute a full reload
				Turbo.visit(url)
			}

		});






		

  }
}
