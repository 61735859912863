(function (global, factory) {
      typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
      typeof define === 'function' && define.amd ? define(factory) :
      (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.Gantt3 = factory());
})(this, (function () { 'use strict';

      var Gantt3 = (function () {

      	/**
      	 * Create the Constructor object
      	 */
      	var Constructor = function (options) {

      		//
      		// Variables
      		//

              var defaults = {
                  selector: '.gentt3',
                  events: '',
                  //someDefaultOption: 'foo',
                  //classToAdd: "new-class-name"
              };

              let startDate = new Date();
              let worksites = [];
              let currentWorksiteId = 0;
              let viewType = "W";


      		// Public APIs object
      		var publicAPIs = {};


      		//
      		// Methods
      		//

      		/**
      		 * A private method
      		 */
      		var somePrivateMethod = function () {
      			// Code goes here...
      			console.log("fai qualcosa Gantt!!!");
      			$("#gantt3").html("okkkkk");
      		};

      		var getEvents = async function (baseURL) {
      			const response = await fetch(`${baseURL}`);
      		  const result = await response.json();
      		  return result;
      		};		

              var getWorksites = async function (baseURL) {
                const response = await fetch(`${baseURL}`);
                const result = await response.json();
                return result;
              };

              var createEvent = function(){  
                alert("create shift");
              };

              var createShift = function(){  
                alert("create event");
              }; 

              var next = async function(){  

                //events: '/shifts/get_planning.json?ws=9&start_date=2023-03-01&view_type=One%20Month',
       
                //scompone l'url di partenza
                let host = options.events.split("?")[0];
                //let params = options.events.split("?")[1]
                //let aParams = params.split("&")
                //console.log(aParams)

                  

                  switch(viewType) {
                    case "One Month":
                      startDate = addMonths(startDate, 1);
                      break;
                    case "W":
                      startDate = addDays(startDate, 7);
                      break;
                    case "2W":
                      startDate = addDays(startDate, 14);
                      break;
                    case "D":
                      startDate = addDays(startDate, 1);
                      break;
                      // code block
                  }


                
                //format for request
                //let newStartDate = format(startDate, "yyyy-MM-dd")


                //const ev = await getEvents(host + "?" + params)
                const ev = await getEvents(host + "?ws=" + currentWorksiteId + "&start_date=" + format(startDate, "yyyy-MM-dd") + "&view_type=" + viewType);
                console.log( ev);
                renderGantt(options.selector, ev, worksites);
              };

              var previous = async function(){  
                console.log("previous");

               
       
                //scompone l'url di partenza
                let host = options.events.split("?")[0];
                  
                  switch(viewType) {
                    case "One Month":
                      startDate = addMonths(startDate, -1);
                      break;
                    case "W":
                      startDate = addDays(startDate, -7);
                      break;
                    case "2W":
                      startDate = addDays(startDate, -14);
                      break;
                    case "D":
                      startDate = addDays(startDate, -1);
                      break;
                      // code block
                  }


                const ev = await getEvents(host + "?ws=" + currentWorksiteId + "&start_date=" + format(startDate, "yyyy-MM-dd") + "&view_type=" + viewType);
                //const ev = await getEvents(host + "?" + params)
                console.log( ev);
                renderGantt(options.selector, ev, worksites);


              };


              var changeWorksite = async function(worksiteId){
                //scompone l'url di partenza
                let host = options.events.split("?")[0];


                currentWorksiteId = worksiteId;

                const ev = await getEvents(host + "?ws=" + currentWorksiteId + "&start_date=" + format(startDate, "yyyy-MM-dd") + "&view_type=" + viewType);
                //const ev = await getEvents(host + "?" + params)
                console.log(ev);
                renderGantt(options.selector, ev, worksites);
              };


              var changeView = async function(view){
                //alert(view)
                //scompone l'url di partenza
                let host = options.events.split("?")[0];
                
                viewType = view;

                  switch(viewType) {
                    case "One Month":
                      startDate = startOfMonth(startDate);
                      break;
                    case "W":
                      startDate = startOfWeek(startDate, { weekStartsOn: 1 });
                      break;
                    case "2W":
                      startDate = startOfWeek(startDate, { weekStartsOn: 1 });
                      break;
                      // code block
                  }          

                const ev = await getEvents(host + "?ws=" + currentWorksiteId + "&start_date=" + format(startDate, "yyyy-MM-dd") + "&view_type=" + viewType);
                console.log(ev);
                renderGantt(options.selector, ev, worksites);

              };

              var today = function(){  
                alert("today");
              };        


              var renderGantt = function(selector, data, worksites) {

             
                  console.log(data.people);

                  //clear
                  document.querySelector(selector).innerHTML="";

                  //var startDate = new Date("2023", "2", "1", "0", "0")
                  //var viewType = "week"
                  var intervals = 7;

                  switch(viewType) {
                    case "One Month":
                      intervals = 31;
                      break;
                    case "W":
                      intervals = 7;
                      break;
                    case "2W":
                      intervals = 14;
                      break;
                    case "D":
                      intervals = 24; //hours
                      break;
                      // code block
                  }

                  data.people.map(function(person){
                      //console.log(person)
                  });


                      // create control panel
                      let controlPanel = document.createElement("div");
                      controlPanel.setAttribute("class", "o_control_panel");
                      //controlPanel.innerHTML = "control panel";
                      // lo appende al div specificato nel selector
                      document.querySelector(selector).appendChild(controlPanel);     
                      // create control panel top      
                      let controlPanelTop = document.createElement("div");
                      controlPanelTop.setAttribute("class", "o_control_panel_top");
                      
                      // lo appende al div specificato nel selector
                      controlPanel.appendChild(controlPanelTop);  
                      // create control panel bottom      
                      let controlPanelBottom = document.createElement("div");
                      controlPanelBottom.setAttribute("class", "o_control_panel_bottom");
                      //controlPanelBottom.innerHTML = "control panel bottom";
                      // lo appende al div specificato nel selector
                      controlPanel.appendChild(controlPanelBottom); 

                      /**
                       * Create and append worksite select list
                       */                
                      var selectList = document.createElement("select");
                      selectList.id = "worksite-select";
                      selectList.setAttribute("class", "worksites");
                      selectList.setAttribute("data-controller", "select2");
                      controlPanelTop.appendChild(selectList);

                      // Create and append the options
                      worksites.map(function(worksite){
                          console.log(worksite);
                          var option = document.createElement("option");
                          option.value = worksite.id;
                          option.text = worksite.name;
                          selectList.appendChild(option);
                      });
                      selectList.value = currentWorksiteId;

                      // init select2 (select2/jquery dependency)
                      $('select.worksites').select2({
                          theme: 'bootstrap-5'
                      }).on('select2:select', function(e) {
                          var data = e.params.data;
                          var worksiteId = data.id;
                          //window.location = "/shifts?ws=" + placeId + "&start_date=" + $(".simple-calendar").attr("data-start");
                          //window.location = "/timesheets/work_entries?ws=" + placeId;
                          //alert(worksiteId)
                          changeWorksite(worksiteId);
                      });

                      //Create and append select list
                      var viewSelectList = document.createElement("dropdown");
                      viewSelectList.id = "view-select";
                      var html = '<button class="btn btn-bd-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">';
                      html += 'Vista timeline 2';
                      html += '</button>';
                      html += '<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">';
                      html += '<li><a id="mistert" class="dropdown-item" href="/shifts?view=custom" data-turbo="false">Vista personalizzata</a></li>';
                      html += '<li><a id="calendar" class="dropdown-item" href="/shifts?view=calendar" data-turbo="false">Vista calendario</a></li>';
                      html += '<li><a class="dropdown-item" href="/shifts/planning" data-turbo="false">Vista timeline</a></li>';
                      html += '<li><a id="timeline-alt" class="dropdown-item active" href="#">Vista timeline 2</a></li>';
                      html += '</ul>';
                      viewSelectList.innerHTML = html;
                      controlPanelTop.appendChild(viewSelectList);

                      //Create and append the options
                      /*
                      for (var i = 0; i < viewsArray.length; i++) {
                          var option = document.createElement("option");
                          option.value = viewsArray[i];
                          option.text = viewsArray[i];
                          viewSelectList.appendChild(option);
                      }
                      */

                      const dropdownItems = document.querySelectorAll("#view-select .dropdown-menu .dropdown-item");

                      dropdownItems.forEach(item => {
                        item.addEventListener("click", function(e) {
                          // your code here
                          //e.preventDefault();
                          //alert(`Clicked ${e.target.id}!`);
                          if (e.target.id == "mistert") ;
                        });
                      });



                      let newEventButton = document.createElement("button");
                      newEventButton.setAttribute("class", "btn-new-event btn btn-bd-light");
                      newEventButton.innerHTML = "Nuovo evento";
                      newEventButton.style.float = "right";
                      newEventButton.style.marginLeft = "5px";
                      controlPanelTop.appendChild(newEventButton);

                      newEventButton.addEventListener("click", function(){
                        createEvent();
                      });                
                      
                      let newShiftButton = document.createElement("button");
                      newShiftButton.setAttribute("class", "btn-new-shift btn btn-bd-light");
                      newShiftButton.innerHTML = "Nuovo turno";
                      newShiftButton.style.float = "right";
                      newShiftButton.style.marginLeft = "5px";
                      controlPanelTop.appendChild(newShiftButton);

                      newShiftButton.addEventListener("click", function(){
                        createShift();
                      });


                      //button group
                      let buttons = document.createElement("div");
                      buttons.setAttribute("class", "btn-group");
                      buttons.setAttribute("role", "group");
                      buttons.style.float = "right";
                      html = '<button id="btn-month" type="button" title="Mese" class="btn-month btn btn-primary">Mese</button><button id="btn-two-weeks" type="button" title="Due settimane" class="btn-two-weeks btn btn-primary">Due settimane</button><button id="btn-week" type="button" title="Settimana" class="btn-week btn btn-primary">Settimana</button><button id="btn-day" type="button" title="Giorno" class="btn-day btn btn-primary">Giorno</button>';
                      buttons.innerHTML = html;
                      controlPanelTop.appendChild(buttons);

                      let monthButton = document.getElementById("btn-month");
                      monthButton.addEventListener("click", function(){
                        alert("month view");
                        changeView("One Month");
                      });                

                      let twoWeeksButton = document.getElementById("btn-two-weeks");
                      twoWeeksButton.addEventListener("click", function(){
                        alert("two weeks view");
                        changeView("2W");
                      }); 

                      let weekButton = document.getElementById("btn-week");
                      weekButton.addEventListener("click", function(){
                        alert("week view");
                        changeView("W");
                      });  

                      let dayButton = document.getElementById("btn-day");
                      dayButton.addEventListener("click", function(){
                        alert("day view");
                        changeView("D");
                      }); 

                      // navigation toolbar
                      let navToolbar = document.createElement("div");
                      navToolbar.setAttribute("class", "nav-toolbar");
                      controlPanelBottom.appendChild(navToolbar);

                      // navigation toolbar buttons group
                      let navToolbarButtonsGroup = document.createElement("div");
                      navToolbarButtonsGroup.setAttribute("class", "btn-group");
                      navToolbar.appendChild(navToolbarButtonsGroup);

                      // prev button
                      let prevButton = document.createElement("button");
                      prevButton.setAttribute("class", "prev-btn btn btn-default");
                      prevButton.setAttribute("title", "Prec");
                      prevButton.innerHTML = '<span class="bi bi-chevron-left"></span>';
                      navToolbarButtonsGroup.appendChild(prevButton);

                      // today button
                      let todayButton = document.createElement("button");
                      todayButton.setAttribute("class", "today-btn btn btn-default");
                      todayButton.setAttribute("title", "Oggi");
                      todayButton.innerHTML = "Oggi";
                      navToolbarButtonsGroup.appendChild(todayButton);                

                      // next button
                      let nextButton = document.createElement("button");
                      nextButton.setAttribute("class", "next-btn btn btn-default");
                      nextButton.setAttribute("title", "Succ");
                      nextButton.innerHTML = '<span class="bi bi-chevron-right"></span>';
                      navToolbarButtonsGroup.appendChild(nextButton);

                      nextButton.addEventListener("click", function(){
                        next();
                      });
                        
                      prevButton.addEventListener("click", function(){
                        previous();
                      });




                      todayButton.addEventListener("click", function(){
                        today();
                      });


                      // date picker button
                      let endDate;
                      let periodDescription = "";
                      switch(viewType) {
                        case "One Month":
                          endDate = addDays(addMonths(startDate,1),-1);
                          periodDescription = format(startDate, "MMMM yyyy");
                          break;
                        case "W":
                          endDate = addDays(startDate,6);
                          periodDescription = format(startDate, "dd MM yyyy") + " - " + format(endDate, "dd MM yyyy");
                          break;
                        case "2W":
                          endDate = addDays(startDate,13);
                          periodDescription = format(startDate, "dd MM yyyy") + " - " + format(endDate, "dd MM yyyy");
                          break;
                        case "D":
                          periodDescription = format(startDate, "dd MMMM yyyy");
                          break;
                          // code block
                      }

                      let datePickerButton = document.createElement("button");
                      datePickerButton.setAttribute("class", "date-picker-btn btn btn-default");
                      datePickerButton.setAttribute("title", periodDescription);
                      datePickerButton.innerHTML = periodDescription;
                      navToolbar.appendChild(datePickerButton);

                      todayButton.addEventListener("click", function(){
                        today();
                      });

                      //html = '<div class="btn-group"><button type="button" title="Prec" class="prev-btn btn btn-primary"><span class="bi bi-chevron-left"></span></button><button type="button" title="Succ" class="next-btn btn btn-primary"><span class="bi bi-chevron-right"></span></button></div><button type="button" title="Oggi" disabled="" aria-pressed="false" class="today-btn btn btn-primary">Oggi</button>'
                      //navigationButtons.innerHTML = html;
                      //controlPanelBottom.appendChild(navigationButtons);

                      // crea il contenitore
                      let controlContainer = document.createElement("div");
                      controlContainer.setAttribute("class", "o_planning_gantt");
                      // lo appende al div specificato nel selector
                      document.querySelector(selector).appendChild(controlContainer);

                
                      let headerSidebar = document.createElement("b");
                      headerSidebar.setAttribute("class", "o_gantt_header_sidebar");
                      //headerSidebar.innerHTML = "Pianificazione";
                      controlContainer.appendChild(headerSidebar);
                      


                      let headerSlots = document.createElement("div");
                      headerSlots.setAttribute("class", "o_gantt_header_slots");
                      //grid-template-columns: repeat(7, calc(100% / 7));
                      headerSlots.style.gridTemplateColumns = "repeat("+intervals+", calc(100% / "+intervals+"))";

                      // crea le celle per le date
                      var headerCell;
                      for (var i=0; i<intervals; i++) {
                          headerCell = document.createElement("div");
                          headerCell.setAttribute("class", "o_gantt_header_cell");
                          if (viewType=="D") {
                            headerCell.innerHTML = i;      
                          } else {
                            console.log(format(addDays(startDate, i),"d eee"));
                            headerCell.innerHTML = format(addDays(startDate, i),"d eee");                      
                          }

                          headerSlots.appendChild(headerCell);
                      }

                      controlContainer.appendChild(headerSlots);
                      
                      let rowHeaders = document.createElement("div");
                      rowHeaders.setAttribute("class", "o_gantt_row_headers");
                      controlContainer.appendChild(rowHeaders);
                      //rowHeaders.innerHTML = "row headers";

                      let cells = document.createElement("div");
                      cells.setAttribute("class", "o_gantt_cells");
                      if (viewType == "D") {
                        // 10 minutes grid columns
                        cells.style.gridTemplateColumns = "repeat(144, calc(100% / 144))";
                      } else {
                        cells.style.gridTemplateColumns = "repeat("+intervals+", calc(100% / "+intervals+"))";
                      }
                      
                      controlContainer.appendChild(cells);
                      data.people.map(function(person, index){
                          console.log(person);

                          //rowHeaders.style.gridTemplateRows = "repeat(" + (index+1)  +", 24px)"
                          //rowHeaders.style.gridTemplateRows = "repeat("+(index+1)*15+", 8px)"
                          //cells.style.gridTemplateRows = "repeat("+(index+1)*15+", 8px)"


                          let rowHeader = document.createElement("div");
                          rowHeader.setAttribute("class", "o_gantt_row_header");
                          rowHeader.setAttribute("data-resource-id", person.id);
                          
                          //rowHeader.setAttribute("style", "grid-column:1;grid-row:" + (index*15+1) + " / span 15;" )
                          rowHeader.style.gridColumn = 1;
                          rowHeader.style.gridRow = (index*15+1) + " / span 15";


                          //rowHeader.innerHTML = person.name
                           
                          let rowTitle = document.createElement("div");
                          rowTitle.setAttribute("class", "o_gantt_row_title");
                          rowTitle.innerHTML = person.name;
                          rowHeader.appendChild(rowTitle);  

                          rowHeaders.appendChild(rowHeader);  


                          //disegna le celle che conterranno i turni (settimana per il momento)
                          for (var i=0; i<intervals; i++) {
                              let cell = document.createElement("div");
                              cell.setAttribute("class", "o_gantt_cell");
                              cell.setAttribute("data-resource-id", person.id);
                              cell.setAttribute("data-col-index", i+1);

                              //cell.style.gridColumn = i+1;
                              //cell.style.gridRow = index+1; 
                              if (viewType =="D") {
                                cell.setAttribute("style", "grid-column:" + (i*6+1) + " / span 6;grid-row:" + (index*15+1) + " / span 15;" );
                              } else {
                                cell.setAttribute("style", "grid-column:" + (i+1) + ";grid-row:" + (index*15+1) + " / span 15;" );  
                              }
                                             
                              cells.appendChild(cell);
                          }

                          //crea i turni (o_gantt_pill_wrapper)
                          let columnIndex, intToD, columnSpan;
                          person.appointments.map(function(appointment){
                          		console.log(appointment.type);
                              //identifica la colonna (differenza tra lo start del turno e lo start date del gantt)
                              let yyyy = appointment.start.split(" ")[0].split("-")[0];
                              let MM = appointment.start.split(" ")[0].split("-")[1]-1;
                              let dd = appointment.start.split(" ")[0].split("-")[2];
                              let hh = appointment.start.split(" ")[1].split(":")[0];
                              let mm = appointment.start.split(" ")[1].split(":")[1];
                              // end
                              let end_yyyy = appointment.end.split(" ")[0].split("-")[0];
                              let end_MM = appointment.end.split(" ")[0].split("-")[1]-1;
                              let end_dd = appointment.end.split(" ")[0].split("-")[2];
                              let end_hh = appointment.end.split(" ")[1].split(":")[0];
                              let end_mm = appointment.end.split(" ")[1].split(":")[1];

                              if (viewType=="D") {
                                //alert("startDate: " + startDate)
                                intToD = differenceInMinutes(
                                  new Date(yyyy, MM , dd, hh, mm, 0),
                                  startDate
                                );
                                columnSpan = differenceInMinutes(
                                  new Date(end_yyyy, end_MM , end_dd, end_hh, end_mm, 0),
                                  new Date(yyyy, MM , dd, hh, mm, 0)
                                ) / 10;
                                //alert(intToD)
                                // 10 minutes grid setting
                                columnIndex = (intToD / 10) + 1;
                                //alert(columnIndex)
                                //alert(columnSpan)
                              } else {
                                intToD = differenceInCalendarDays(
                                  new Date(yyyy, MM , dd, hh, mm, 0),
                                  startDate
                                );   
                                columnIndex = intToD+1;                       
                              }

                              
                              //crea il turno
                              //if (columnIndex<=intervals) {
                                  let pillWrapper = document.createElement("div");

                                  pillWrapper.setAttribute("data-resource-id", person.id);
                                  pillWrapper.setAttribute("data-col-index", columnIndex);

                                  pillWrapper.setAttribute("class", "o_gantt_pill_wrapper");
                                  //check if position isn't available
                                  let checkPos = document.querySelectorAll('.o_gantt_pill_wrapper[data-resource-id="'+person.id+'"][data-col-index="' + columnIndex + '"]');
                                  if (checkPos.length>0) {
                                      console.log("posizione occupata:" + appointment.start);

                                      /*
                                      
                                      pillWrapper.setAttribute("style", "grid-column:" + columnIndex + ";grid-row:" + (index+1+checkPos.length) + ";" )
                                      if (appointment.backgroundColor != "") {
                                          pillWrapper.style.borderLeft = "0.25rem solid " + appointment.backgroundColor
                                          pillWrapper.style.backgroundColor = appointment.backgroundColor + "33"
                                      } else {
                                          if (appointment.type=="Type06") {
                                              pillWrapper.style.borderLeft = "0.25rem solid #0d6efd"
                                          	pillWrapper.style.backgroundColor = "#0d6efd33"
                                          	//pillWrapper.style.color = "#ffffff"
                                          }
                                      }

                                      //modificare l'altezza della celle contenitore della riga corrente
                                      let rowCells = document.querySelectorAll('.o_gantt_cell[data-resource-id="'+person.id+'"]')
                                      rowCells.forEach((rowCell) => {
                                        console.log(rowCell);
                                        rowCell.style.gridRow = (index+1) + " / span 2"
                                      });
                                      let rowHeader = document.querySelector('.o_gantt_row_header[data-resource-id="'+person.id+'"]')
                                      rowHeader.style.gridRow = (index+1) + " / span 2"

                                      */
                                                                

                                  } else {
                                    if (viewType=="D") {
                                      pillWrapper.setAttribute("style", "grid-column:" + columnIndex + " / span " + columnSpan + ";grid-row:" + (index*15+1) + " / span 15;" );
                                    } else {
                                      pillWrapper.setAttribute("style", "grid-column:" + columnIndex + ";grid-row:" + (index*15+1) + " / span 15;" );
                                    }
                                    pillWrapper.style.backgroundColor = "#ffffff";                   
                                  }
                                      
                                  // create inner cell
                                  let pill = document.createElement("div");
                                  pill.setAttribute("class", "o_gantt_pill");
                                  if (appointment.backgroundColor != "") { 
                                    pill.style.borderLeft = "0.25rem solid " + appointment.backgroundColor;
                                    pill.style.backgroundColor = appointment.backgroundColor + "33";
                                  } else {
                                    pill.style.borderLeft = "0.25rem solid #0057d2";
                                    pill.style.backgroundColor = "#d1efff";
                                  }
                                  pill.innerHTML = hh + ":" + mm + "-" + end_hh + ":" + end_mm;
                                  pillWrapper.appendChild(pill);
                                  cells.appendChild(pillWrapper);
                              //}
                          });

                      });
              };



      		/**
      		 * A public method
      		 */
      		publicAPIs.doSomething = function () {
      			somePrivateMethod();
      			// Code goes here...
      		};

      		/**
      		 * Another public method
      		 */
      		var init = async function () {

      			console.log("gantt2 running");

            // Merge user options with defaults
            options = { ...defaults, ...options };

            console.log(options);
            console.log(options.selector);
            console.log(options.events);

            // get worksites
            worksites = await getWorksites(options.worksites);
            console.log(worksites);

            // set events defaults
            currentWorksiteId=worksites[0].id;
            
            startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
            viewType = "W";

      			const ev = await getEvents(options.events + "?ws=" + currentWorksiteId + "&start_date=" + format(startDate, "yyyy-MM-dd") + "&view_type=" + viewType);
      			console.log(ev);
      			renderGantt(options.selector, ev, worksites);




      		};


      		//
      		// Return the Public APIs
      		//

      		init();
      		return publicAPIs;

      	};


      	//
      	// Return the Constructor
      	//

      	return Constructor;

      })();

      return Gantt3;

}));
