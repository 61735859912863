import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

// Connects to data-controller="event-form"
export default class extends Controller {

	static targets = [ "copytodays" ]
  
  connect() {
    console.log("event-form-controller connect")

    //get start date
    var startDate = $(".simple-calendar").attr("data-start");
    $('.datepicker').datepicker({
      multidate: true,
      format: "yyyy-mm-dd",
      startDate: startDate,
      language: "it-IT"
    });
    $(".context-menu").remove()
  }

  refreshPlanningCalendar() {
    console.log("refreshPlanningCalendar")
    window.dispatchEvent(new CustomEvent("refreshFC"));
    window.dispatchEvent(new CustomEvent("refreshPlanningCalendar"));
  }

  async copy() {

    //get form values   
    var date = $(this.element).find("#event_date").val()
    var startTime = $(this.element).find("#event_s_time").val()
    var endTime = $(this.element).find("#event_e_time").val()
    var peopleId = $(this.element).find("#event_employee_id").val()
    var type = $(this.element).find("#event_event_type_id").val()
    var locationId = $(this.element).closest(".simple-calendar").attr("data-worksite")

    var copyPeopleId = $(this.element).find("#event_copy_employee_id").val()
    if (copyPeopleId != "") {
      peopleId=copyPeopleId
    }

    var dates = this.copytodaysTarget.value

    const response = await post('/events/copy', { 
      body: JSON.stringify({ lid: locationId, type: type, pid: peopleId, dates: dates, stime: startTime, etime: endTime }),
      responseKind: "turbo-stream"
    })

  }


}
